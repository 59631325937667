import React from 'react';

import { useModal } from 'hooks/useModal';

import PdfButtons from 'pages/results/PdfButtons';
import DicomDropdown from 'pages/results/DicomDropdown';
import AccessionButton from 'pages/results/AccessionButton';

// There are three types of RIS exams:
// - ResultType = 77 -> displayed as an accession button
// - ResultType = 88 -> displayed as examTitle and some text 
// - ResultType = 99 -> displayed as two paragraphs: findings and conclusion
// The text style is 'pre-wrap' so that it maintains any formatting applied by the author.
// There are also three buttons on top: DICOM viewer (if available, for delegates only), PDF view and download.
// On the bottom, there might be a signature.
export default function ExamGroupRIS({ groupData, groupLayout }) {
    
    function generateGroupStructure() {
        let textFields = [], buttonFields = [];
        
        Object.values(groupData["Tests"]).forEach(val => {
            val["Fields"].forEach(fieldObj => {
                switch (fieldObj["ResultType"]) {
                    case "77":
                        buttonFields.push({
                            title: null,
                            accession: fieldObj["Result"]
                        });
                        break;
                    case "88":
                        textFields.push({
                            title: fieldObj["FieldFullName"],
                            text: fieldObj["Result"]
                        });
                        break;
                    case "99":
                        if (fieldObj["Result"]) {
                            textFields.push({
                                title: 'Πόρισμα',
                                text: fieldObj["Result"]
                            });
                        }
                        if (fieldObj["Comment"]) {
                            textFields.push({
                                title: 'Συμπέρασμα',
                                text: fieldObj["Comment"]
                            });
                        }
                        break;
                    default:
                        break;
                }
            })
        });
        return [ textFields, buttonFields ];
    }
    
    const modal = useModal();
    const [ textFields, buttonFields ] = generateGroupStructure();

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end align-items-stretch btn-print">
                <PdfButtons 
                    orderId={modal.orderId}
                    printGroupId={groupData.PrintGroupId}
                    printGroupName={groupData.PrintGroupName}
                    isWritable={true}
                />
                <DicomDropdown groupTestObj={groupData['Tests']} />
            </div>
            <div className="group-body fs-7" style={{ whiteSpace: 'pre-wrap' }}>
                {
                    textFields.map(f => (
                        <React.Fragment key={f.title}>
                            <h5 className="bold fs-7 mt-3">{f.title}</h5>
                            <span className='mb-2'>{f.text}</span>
                        </React.Fragment>
                    ))
                }
                {
                    buttonFields.map((f, i) => (
                        <AccessionButton key={f.accession} accessionNumber={f.accession}>Link {i + 1}</AccessionButton>
                    ))
                }
            </div>
            <div className="group-footer">
                <div className="row">
                    <div className="col-8"></div>
                    <div className="col-4 text-center" id="signature">
                        {groupLayout['GroupSigner']}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}