import React, { useState } from 'react';

import { LINKS } from 'data/links';
import { useAuth } from 'hooks/useAuth';
import { useFetch } from 'hooks/useFetch';
import { useToast } from 'hooks/useToast';

export default function PdfButtons({ orderId, printGroupId, printGroupName, isWritable, mode }) {

    const auth = useAuth();
    const toast = useToast();
    const fetchFunc = useFetch({ secure: true });
    const [ btnDisabled, setBtnDisabled ] = useState(false);

    // This function queries the API for the results PDF of a specific print group.
    // If there is a PDF, the file downloads automatically. If not, it makes a generation request.
    function downloadPdfPrintGroup(mode) {
        setBtnDisabled(true);
        fetchFunc(LINKS.GET_PDF_PRINT_GROUP, {
            method: 'POST',
            responseType: 'blob',
            body: JSON.stringify({
                order_id: orderId,
                pg_id: printGroupId,
                is_writable: isWritable,
                pa_code: auth.user.userId
            })
        })
        .then(resp => {
            // If response status is 200, the response contains a blob. Else a JSON object.
            if (resp.status === 200) {
                return resp.blob().then(respData => ({ status: resp.status, data: respData }))
            }
            else {
                return resp.json().then(respData => ({ status: resp.status, data: respData }))
            }
        })
        .then(({ status, data }) => {
            setBtnDisabled(false);
            switch (status) {
                case 200:
                    // Status = 200 -> PDF is ready for download.
                    switch (mode) {
                        case 'show':
                            const file = new Blob([data], {
                                type: 'application/pdf'
                            });
                            const fileURL = window.URL.createObjectURL(file);
                            window.open(fileURL);
                            window.URL.revokeObjectURL(fileURL);
                            break;
                        case 'download':
                            const blobURL = window.URL.createObjectURL(data);
                            const a = document.createElement("a")
                            document.body.appendChild(a);
                            a.href = blobURL;
                            a.download = `${orderId}-${printGroupId}.pdf`;
                            a.click()
                            a.remove();
                            window.URL.revokeObjectURL(blobURL); 
                            break;  
                        default:
                            break;
                    }
                    break;
                // Status = 210 -> Something went wrong, as requested exams = 0.
                case 210:
                    toast.addToast("Νέο αίτημα PDF", `Καταχωρήθηκε αίτημα δημιουργίας του PDF αποτελεσμάτων για την εκτυπωτική ομάδα ${printGroupName}. (exams = 0)`);
                    break;
                // Status = 220 -> PDF request is pending.
                // Status = 221 -> A new PDF request has been made.
                case 220:
                case 221:
                    toast.addToast("Αναμονή για PDF", `Έχει καταχωρηθεί αίτημα δημιουργίας για την εκτυπωτική ομάδα ${printGroupName}. Παρακαλώ ελέγξτε πάλι σε λίγο.`);
                    break;
                // Status = 230 -> PDF request is pending, but PDF generation service is temporarily down.
                case 230:
                    toast.addToast("Αναμονή για PDF", `Έχει καταχωρηθεί αίτημα δημιουργίας για την εκτυπωτική ομάδα ${printGroupName}. Παρακαλώ ελέγξτε μετά τις ${data.not_before}.`);
                    break;
                // Status = 500 -> An error has occurred.
                case 500:
                    toast.addToast("Σφάλμα", "Παρουσιάστηκε σφάλμα κατά την λήψη του PDF. Παρακαλώ δοκιμάστε αργότερα.");
                    break;
                default:
                    break;
            }
        })
        .catch(e => {
            console.error(e);
            setBtnDisabled(false);
        })
    }

    return (
        <React.Fragment>
            { btnDisabled && <div className="spinner-border text-primary me-2" role="status" /> }
            <button 
                className="btn btn-primary px-2 py-1 mx-2" 
                onClick={() => downloadPdfPrintGroup('show')}  
                title='Προβολή αρχείου PDF'
                disabled={btnDisabled}
            >
                <i className="bi bi-filetype-pdf" />
            </button>
            <button 
                className="btn btn-primary px-2 py-1" 
                onClick={() => downloadPdfPrintGroup('download')} 
                title='Λήψη αρχείου PDF'
                disabled={btnDisabled}
            >
                <i className="bi bi-download" />
            </button>
        </React.Fragment>
    );
}
