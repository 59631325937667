import React from 'react';

export default function TableAlert({ statusObj }) {
    switch(`${statusObj.operation}-${statusObj.status}`) {
        case 'remind-success':
        case 'reactivate-success':
            return (
                <div className="alert alert-success">
                    Έχουν σταλεί e-mail με οδηγίες στην διεύθυνση που δηλώσατε. Ο κωδικός πρόσβασης στο Diagnosis Portal είναι: <b>{statusObj.pwd}</b>
                </div>
            );
        case 'remind-partial-success':
            return (
                <div className="alert alert-success">
                    Έχει ήδη σταλεί ένα e-mail με οδηγίες και η επανάληψη της αποστολής μπορεί να γίνει σε {statusObj.nextRemindIn} λεπτά. O κωδικός πρόσβασης στο Diagnosis Portal είναι: <b>{statusObj.pwd}</b>
                </div>
            );
        case 'revoke-success':
            return (
                <div className="alert alert-success">
                    Η παραχώρηση πρόσβασης στο ιατρικό σας φάκελο έχει ανακληθεί επιτυχώς.
                </div>
            );
        case 'remind-error':
        case 'reactivate-error':
        case 'revoke-error':
        case 'list-error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}
