function fillFrameDocument(frameDocument, tableId, chartId) {
    appendBody(frameDocument, tableId, chartId);
    appendStyle(frameDocument);
}

function generateIframe() {
    const iframe = document.createElement("iframe");
    iframe.id = 'print-iframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    return [ iframe, iframe.contentWindow.document ];
}

function generateWindow() {
    const newWindow = window.open('', '_blank', 'menubar=no,status=no');
    return [ newWindow, newWindow.document ];
}

function appendBody(fdoc, tableId, chartId) {
    const bodyDiv = fdoc.createElement("div");
    const table = fdoc.importNode(document.querySelector(`#${tableId}`), true);
    bodyDiv.appendChild(table);
    const chart = fdoc.importNode(document.querySelector(`#${chartId}`), true);
    bodyDiv.appendChild(chart);
    fdoc.body.appendChild(bodyDiv);
}

function appendStyle(fdoc) {
    const styleElement = fdoc.createElement("style");
    styleElement.innerHTML = getDocumentStyling();
    fdoc.head.appendChild(styleElement);
}

function getDocumentStyling() {
    let styleSheetArr = Array.from(document.styleSheets);
    // [ [cssRule1.1, cssRule1.2, ...], [cssRule2.1, cssRule2.2, ...], ... ]
    let rulesArrArr = styleSheetArr.map(styleSheet => Array.from(styleSheet.cssRules));
    // [ [cssText1.1, cssText1.2, ...], [cssText2.1, cssText2.2, ...], ... ]
    let cssTextsArrArr = rulesArrArr.map(ruleArr => ruleArr.map(rule => rule.cssText));
    // [ largeCssText1, largeCssText2 ]
    let largeCssTextArr = cssTextsArrArr.map(cssTextArr => cssTextArr.join(''));
    // superLargeCssText
    let css = largeCssTextArr.join('');
    return css;
}

function printExamHistory(tableId, chartId, event) {
    const ua = window.navigator.userAgent;
    
    if (ua.includes("Chrome")) {
        const [ iframe, frameDocument ] = generateIframe();
        fillFrameDocument(frameDocument, tableId, chartId);
        iframe.contentWindow.print();
        // document.body.removeChild(iframe);
    }
    else if (ua.includes("Firefox")) {
        const [ newWindow, frameDocument ] = generateWindow();
        fillFrameDocument(frameDocument, tableId, chartId);
        frameDocument.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
    }
    else {
        alert("Το πρόγραμμα περιήγησης δεν υποστηρίζει εκτύπωση");
    }
}
export { generateIframe, generateWindow, appendStyle, printExamHistory };