import { isMobile } from "react-device-detect";
import { usePromiseTracker } from "react-promise-tracker";

function LoadingIndicator({ area }) {
    const { promiseInProgress } = usePromiseTracker({ area });
    return promiseInProgress && 
        <div className="my-3 col-12 d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status"></div>
        </div>
}

function LoadingIndicatorSm({ area }) {
    const { promiseInProgress } = usePromiseTracker({ area });
    return promiseInProgress && 
    <div className="spinner-border text-primary" role="status"></div>
}

function LoadingIndicatorInline({ area }) {
    const { promiseInProgress } = usePromiseTracker({ area });
    if (!promiseInProgress) return <></>;
    if (isMobile) return (
        <span className="spinner-grow ms-1" style={{ width: '0.6rem', height: '0.6rem' }} role="status" />
    )
    else return (
        <div className="spinner-border spinner-border-sm text-white ms-2" role="status" />
    )
}

export default LoadingIndicator;
export { LoadingIndicatorSm, LoadingIndicatorInline };