const BASE_URL = (process.env.NODE_ENV === 'production') ? "/api" : "http://192.168.10.101:5000";

export const LINKS = {
    
    PWD_POLICY: BASE_URL + "/pwdpolicy",
    AUTH_LOGIN: BASE_URL + "/auth/login",
    AUTH_FORGOT: BASE_URL + "/auth/forgot",
    AUTH_RECOVER: BASE_URL + "/auth/recover",
    AUTH_REFRESH: BASE_URL + "/auth/refresh",
    AUTH_ACTIVATE: BASE_URL + "/auth/activate",
    VERIFY_RECOVERY_TOKEN: BASE_URL + "/auth/token/recover",
    VERIFY_ACTIVATION_TOKEN: BASE_URL + "/auth/token/activate",

    USER_INFO: BASE_URL + "/user/info",

    XML_GET_VISITS: BASE_URL + "/order/tree",
    XML_GET_EXAM_PROFILE: BASE_URL + "/exam/profile",
    XML_GET_EXAM_HISTORY: BASE_URL + "/exam/history",
    XML_GET_ORDER_RESULTS: BASE_URL + "/order/results",
    XML_GET_ORDER_LAYOUT: BASE_URL + "/order/layout",
    CHECK_EXAM_STATUS: BASE_URL + "/order/exam/status",
    GET_DOCUMENT: BASE_URL + "/order/document",
    GET_DICOM_URL: BASE_URL + "/order/exam/dicom",
    GET_RIS_INFO: BASE_URL + "/order/ris",
    
    GET_PDF_MERGED: BASE_URL + "/order/pdf/merged",
    GET_PDF_PRINT_GROUP: BASE_URL + "/order/pdf/printgroup",

    DELEGATE_ADD: BASE_URL + "/delegate/add",
    DELEGATE_LIST: BASE_URL + "/delegate/list",
    DELEGATE_REVOKE: BASE_URL + "/delegate/revoke",
    DELEGATE_REMIND: BASE_URL + "/delegate/remind",
    DELEGATE_VERIFY: BASE_URL + "/delegate/verify",
    DELEGATE_LOGIN: BASE_URL + "/delegate/login",

    MANUAL_WEB: "/static/diagnosis_manual_web.pdf",
    MANUAL_MOBILE: "/static/diagnosis_manual_mobile.pdf",

    MAINTENANCE_PDF: BASE_URL + "/maintenance/pdf",
    MAINTENANCE_DB: BASE_URL + "/maintenance/db",

    REGISTER_PREPARE: BASE_URL + "/register/prepare",
    REGISTER_FINALIZE: BASE_URL + "/register/finalize",

    ACTIVATE_HASH_PREPARE: BASE_URL + "/activate/prepare",
    ACTIVATE_HASH_FINALIZE: BASE_URL + "/activate/finalize"

}