import { LINKS } from 'data/links';
import { useJsonFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export default function MaintainanceNotification() {

    const [ show, setShow ] = useState(false);
    const [ alertData, setAlertData ] = useState();
    const fetchFunc = useJsonFetch({ secure: false });

    useEffect(() => {
        Promise.all([
            fetchFunc(LINKS.MAINTENANCE_DB, { method: 'GET' }),
            fetchFunc(LINKS.MAINTENANCE_PDF, { method: 'GET' })
        ])
        .then(([ dbData, pdfData ]) => {
            setShow(true);
            if (dbData.status === 'db-down') {
                setAlertData({
                    type: 'danger',
                    msg: `To Diagnosis Portal δεν είναι διαθέσιμο λόγω συντήρησης από ${dbData.start_time} έως ${dbData.end_time}.`
                });
                setShow(true);
            }
            else if (pdfData.status === 'pdf-down') {
                setAlertData({
                    type: 'danger',
                    msg: `Η υπηρεσία δημιουργίας PDF δεν είναι διαθέσιμη από ${pdfData.start_time} έως ${pdfData.end_time}.`
                });
                setShow(true);
            }
            else if (pdfData.status === 'pdf-down-soon') {
                setAlertData({
                    type: 'warning', 
                    msg: `Η υπηρεσία δημιουργίας PDF δεν θα είναι διαθέσιμη από ${pdfData.start_time} έως ${pdfData.end_time}.`
                });
                setShow(true);
            }
            else {
                setAlertData(null);
                setShow(false);
            }
        })
        .catch(e => {
            console.error(e);
            setAlertData(null);
            setShow(false);
        })
    }, [fetchFunc])

    if (show) return (
        <Alert variant={alertData.type} style={{ fontSize: '15px' }} onClose={() => setShow(false)} dismissible >
            {alertData.msg}
        </Alert>
    )
    else return <></>;
}