import React from 'react';
import { ROUTES } from 'data/routes';
import { isBrowser } from 'react-device-detect';

export default function Home() {
    return (
        <React.Fragment>
            <h4 className='mb-4'>Καλώς ήλθατε στο Diagnosis Portal</h4>
            <div className={`col-md-10 ${isBrowser ? 'fs-6' : 'fs-7'}`}>
                <p>Το ITCareSYS Diagnosis Portal είναι μία καινοτόμα υπηρεσία ιατρικού φακέλου που επιτρέπει στους εξεταζόμενους να έχουν ΑΣΦΑΛΗ πρόσβαση στο αρχείο των ιατρικών του εξετάσεων.  
                    {(isBrowser) ? ' Επιπλέον, η περιήγηση μέσω υπολογιστή επιτρέπει την γραφική απεικόνιση ιστορικότητας εργαστηριακών μετρήσεων με την μορφή πίνακα και διαγραμμάτων.' : 
                    ' Για γραφική απεικόνιση ιστορικότητας εργαστηριακών μετρήσεων περιηγηθείτε μέσω υπολογιστή.'}
                </p>
                <p>Για να εισέλθετε στο Diagnosis Portal πρέπει να συνδεθείτε. </p>
                <p>Οι ιατροί με εξουσιοδότηση από τους εξεταζόμενούς τους πρέπει να χρησιμοποιήσουν τον σύνδεσμο που τους έχει σταλεί με e-mail.</p>
                <a className="btn btn-primary mt-3" type="button" href={ROUTES.AUTH_LOGIN}>
                    Είσοδος
                </a>
            </div>
            <div className="d-flex justify-content-center">
                <img 
                    src={process.env.PUBLIC_URL + '/ngeu.png'} 
                    width={isBrowser ? '500px' : '350px'} 
                    style={{ position: 'fixed', bottom: '2rem' }}
                    alt='Next-Generation-EU' 
                />
            </div>
        </React.Fragment>
    );
}
