import React, { useContext, useState } from "react";

const ToastContext = React.createContext(null);

// ToastProvider is a global entity for the application, as multiple buttons can add a global toast.
// Every new toast is assigned a unique id from idSeq and its data is appended to toastArr.
// The provider does not display the toasts; this is done by PdfToast component.
function ToastProvider({ children }) {

    const [ idSeq, setIdSeq ] = useState(0);
    const [ toastArr, setToastArr ] = useState([]);

    // addToast function adds a new object with toast data to the array and increases the sequence.
    function addToast(header, body) {
        setToastArr(prevToastArr => {
            let tmpArr = [...prevToastArr];
            tmpArr.push({
                id: idSeq,
                header: header,
                body: body,
                time: new Date().toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false })
            });
            return tmpArr;
        });
        setIdSeq(prevSeq => prevSeq + 1);
    }

    // deleteToast removes the object with a specific id from the toast array.
    function deleteToast(id) {
        setToastArr(prevToastArr => {
            let tmpArr = [...prevToastArr]
            return tmpArr.filter(t => t.id !== id);
        });
    }

    const retValue = {
        toastArr, addToast, deleteToast 
    }
    return <ToastContext.Provider value={retValue}>{children}</ToastContext.Provider>
}

// Create a hook for easy access from multiple components.
function useToast() {
    return useContext(ToastContext);
}

export { ToastProvider, useToast };