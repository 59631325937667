import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';

export default function ActivateAlert({ status }) {

    const alertMobileStyle = isMobile && "p-2 fs-7"

    // This hook runs after successful account activation
    // and increments a count every 100 ms.
    // A progress bar is displayed using this count.
    const [ barCount, setBarCount ] = useState(0);
    useEffect(() => {
        if (status !== 'success') return;
        setTimeout(() => {
            setBarCount(prevCount => prevCount + 1)
        }, 100);
    })

    switch(status) {
        case 'no-fetch':
            return <></>;
        case 'success':
            return (
                <div className={`alert alert-success ${alertMobileStyle}`}>
                    Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς. Θα μεταφερθείτε αυτόματα στην αρχική σελίδα.
                    <div className="progress w-100 mt-2" style={{ height: "3px" }}>
                        <div className="progress-bar bg-success" role="progressbar" style={{width: `${barCount / 50 * 100}%`}} />
                    </div>
                </div>
            );
        case 'error':
            return (
                <div className={`alert alert-danger ${alertMobileStyle}`}>
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'login-failed':
        case 'already-activated':
            return (
                <div className={`alert alert-warning ${alertMobileStyle}`}>
                    Ο λογαρισμός έχει ενεργοποιηθεί. Για να συνδεθείτε πατήστε <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>εδώ</Link>.
                </div>
            );
        case 'email-not-verified':
            return (
                <div className={`alert alert-warning ${alertMobileStyle}`}>
                    Η διεύθυνση e-mail που καταχωρήσατε δεν αντιστοιχεί σε αυτή που έχει καταχωρηθεί στην καρτέλα σας. Παρακαλώ δοκιμάστε ξανά.
                </div>
            )
        default:
            return <></>;
    }
}
