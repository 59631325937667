import { LINKS } from "data/links";
import React, { useContext, useState } from "react";
import { useJsonFetch } from "./useFetch";

const ModalContext = React.createContext(null);

function ModalProvider({ children }) {
    
    const [ orderId, setOrderId ] = useState(null);
    const [ examData, setExamData ] = useState(null);
    const [ orderDate, setOrderDate ] = useState(null);
    const [ infoModalOpen, setInfoModalOpen ] = useState(false);
    const [ resultsModalOpen, setResultsModalOpen ] = useState(false);
    const [ historyModalOpen, setHistoryModalOpen ] = useState(false);
    const [ unsettledModalOpen, setUnsettledModalOpen ] = useState(false);
    
    const fetchFunc = useJsonFetch({ secure: true });
    const [ changeDetected, setChangeDetected ] = useState(false);

    // This variable holds the timestamp after which the next refresh is allowed. The user can refresh data once every 30 minutes.
    const [ nextRefreshTimestamp, setNextRefreshTimestamp ] = useState(() => {
        let now = new Date();
        let then = new Date(now.getTime() + (1 * 60 * 1000));
        return then;
    });
    
    function openResultsModal(selectedVisitId, selectedVisitDate) {
        setResultsModalOpen(true);
        setOrderId(selectedVisitId);
        setOrderDate(selectedVisitDate);
    }

    function closeResultsModal() {
        setResultsModalOpen(false);
        setOrderId(null);
        setOrderDate(null);
    }

    function openHistoryModal(selectedExam) {
        setHistoryModalOpen(true);
        setExamData(selectedExam);
    }

    function closeHistoryModal() {
        setHistoryModalOpen(false);
        setExamData(null);
    }

    // Before an info modals open, a request to API is generated to fetch the latest status of the selected exam.
    // Only one request per minute is allowed, to prevent server overloading.
    // But if one change is detected, a requested is created every time the modal opens until the tree is refreshed.
    function openInfoModal(selectedExam, orderId) {
        const currTimestamp = new Date();
        if (currTimestamp > nextRefreshTimestamp || changeDetected) {
            fetchFunc(LINKS.CHECK_EXAM_STATUS, {
                method: 'POST',
                body: JSON.stringify({
                    order_id: orderId,
                    exam_id: selectedExam.ExamId
                })
            })
            .then(({ has_result }) => {
                setExamData({
                    ...selectedExam,
                    HasResult: has_result
                })
                if (has_result) setChangeDetected(true);
            })
            .catch(e => {
                console.error(e);
                setExamData(selectedExam);
            });
            setNextRefreshTimestamp(new Date(currTimestamp.getTime() + (1 * 60 * 1000)));
        }
        else setExamData(selectedExam);
        setInfoModalOpen(true);
    }

    function closeInfoModal() {
        setInfoModalOpen(false);
        setExamData(null);
    }

    function openUnsettledModal(selectedExamDescr) {
        setUnsettledModalOpen(true);
        setExamData(selectedExamDescr);
    }

    function closeUnsettledModal() {
        setUnsettledModalOpen(false);
        setExamData(null);
    }

    const retValue = { 
        orderId, orderDate, examData,
        resultsModalOpen, openResultsModal, closeResultsModal, 
        historyModalOpen, openHistoryModal, closeHistoryModal,
        infoModalOpen, openInfoModal, closeInfoModal, setChangeDetected,
        unsettledModalOpen, openUnsettledModal, closeUnsettledModal
    }
    return <ModalContext.Provider value={retValue}>{children}</ModalContext.Provider>
}

function useModal() {
    return useContext(ModalContext);
}

export { ModalProvider, useModal };