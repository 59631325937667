import { LINKS } from 'data/links';
import { useParams } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { useJsonFetch } from 'hooks/useFetch';
import React, { useEffect, useMemo, useState } from 'react';

function PwdPolicyAlert({ policy }) {
    return (
        <div>
            <b className='mb-0'>Προϋποθέσεις ασφαλείας κωδικού:</b>
            <ul className='px-3 mb-0'>
                <li>Να αποτελείται από λατινικούς χαρακτήρες, αριθμούς ή ειδικά σύμβολα.</li>
                <li>Να έχει μήκος {policy.min_chars} έως {policy.max_chars} χαρακτήρες.</li>
                <li>Ελάχιστο πλήθος λατινικών χαρακτήρων: 1</li>
                {(policy.min_numeric > 0) && <li>Ελάχιστος πλήθος αριθμών: {policy.min_numeric}</li>}
            </ul>
        </div>
    )
}

function ValidationAlert({ pwdMatch, pwdValid, phoneValid }) {
    
    const phoneMsg = useMemo(() => {
        if (phoneValid === undefined) return null;
        if (!phoneValid) return "Το τηλέφωνο πρέπει να έχει την μορφή 69ΧΧΧΧΧΧΧΧ με 10 ψηφία συνολικά."
        return null;
    }, [phoneValid]);

    const pwdMsg = useMemo(() => {
        if (pwdMatch === undefined || pwdValid === undefined) return null;
        if (!pwdMatch) return "Ο κωδικός πρόσβασης δεν είναι ίδιος στα δύο πεδία.";
        if (!pwdValid) return "Ο κωδικός πρόσβασης δεν πληροί τις προϋποθέσεις ασφαλείας."
        return null;
    }, [pwdMatch, pwdValid])

    if (phoneMsg == null && pwdMsg == null) return <></>;
    else if (pwdMsg == null) return <div className="alert alert-danger">{phoneMsg}</div>
    else if (phoneMsg == null) return <div className="alert alert-danger">{pwdMsg}</div>
    else return (
        <div className="alert alert-danger pb-0">
            <ul>
                <li>{phoneMsg}</li>
                <li>{pwdMsg}</li>
            </ul>
        </div>
    );
}

export default function NewPwdForm({ email, submitCallback, clearCallback }) {

    const [ phone, setPhone ] = useState('')
    const [ pwdFirstTry, setPwdFirstTry ] = useState('');
    const [ pwdSecondTry, setPwdSecondTry ] = useState('');
    const [ pwdPolicy, setPwdPolicy ] = useState('');
    const [ pwdMatch, setPwdMatch ] = useState(undefined);
    const [ pwdValid, setPwdValid ] = useState(undefined);
    const [ phoneValid, setPhoneValid ] = useState(undefined);
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    const { jwt } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    useEffect(() => {
        fetchFunc(LINKS.PWD_POLICY, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        })
        .then(({ msg, policy }) => {
            if (policy !== undefined) setPwdPolicy(policy);
        })
        .catch(e => {
            console.error(e);
        })
    }, [])

    // This function performs the necessary checks and updates the state.
    function submitHandler(e) {

        // Prevent default submission behavior
        e.preventDefault();

        // Check if passwords provided match
        setPwdMatch(pwdFirstTry === pwdSecondTry);

        // Check if password complies with the password policy
        const regex = new RegExp(`^(?=.*?[a-zA-Z])(?=(.*?\\d){${pwdPolicy.min_numeric}})[A-Za-z0-9#$@!%&*?]{${pwdPolicy.min_chars},${pwdPolicy.max_chars}}$`);
        setPwdValid(regex.test(pwdFirstTry))
        
        // Check if phone format is valid
        setPhoneValid(/69[0-9]{8}/.test(phone));
    }

    // This function is invoked when the user types into the form and updates the state using setCallback.
    // It submit is disabled, it also sets all validity variables to undefined, so that useEffect can run again after new submission.
    // clearCallback resets the activationStatus to eliminate any messages.
    function inputHandler(e, setCallback) {
        if (submitDisabled) {
            setPhoneValid(undefined);
            setPwdMatch(undefined);
            setPwdValid(undefined);
            setSubmitDisabled(false);
            clearCallback();
        }
        setCallback(e.target.value);
    }

    // setState is by default asynchronous. Therefore, submitHandler updates the state
    // and once the state is updated, useEffect will run.
    // If data is validated, then submitCallback function will be executed.
    useEffect(() => {
        if (pwdMatch && pwdValid && phoneValid) {
            submitCallback(pwdFirstTry, phone);
            setSubmitDisabled(true);
        }
    }, [pwdMatch, pwdValid, phoneValid]);
    
    return (
            <div className="row justify-content-center">
                <div className="col-12 border border-2 rounded-2 px-4 py-3">
                    <form className="row g-3" onSubmit={submitHandler}>
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label-sm">Email</label>
                            <input type="text" className="form-control" id="email" value={email} disabled />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone" className="form-label-sm">Κινητό τηλέφωνο</label>
                            <input type="text" className={`form-control ${(phoneValid === false) && 'is-invalid'}`} id="phone" value={phone} onChange={e => inputHandler(e, setPhone)} required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="pwdFirstTry" className="form-label-sm">Νέος κωδικός πρόσβασης</label>
                            <input type="password" className={`form-control ${(pwdValid === false || pwdMatch === false) && 'is-invalid'}`} id="pwdFirstTry" value={pwdFirstTry} onChange={e => inputHandler(e, setPwdFirstTry)} required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="pwdSecondTry" className="form-label-sm">Κωδικός πρόσβασης ξανά</label>
                            <input type="password" className={`form-control ${(pwdValid === false || pwdMatch === false) && 'is-invalid'}`} id="pwdSecondTry" value={pwdSecondTry} onChange={e => inputHandler(e, setPwdSecondTry)} required />
                        </div>
                        {(isBrowser) ?
                            <div className="col-12 d-flex justify-content-between align-items-end">
                                <PwdPolicyAlert policy={pwdPolicy} />
                                <button type="submit" className="btn btn-primary" disabled={submitDisabled}>Υποβολή</button>
                            </div>
                            :
                            <div>
                                <PwdPolicyAlert policy={pwdPolicy} />
                                <div className="d-flex justify-content-end mt-2">
                                    <button type="submit" className="btn btn-primary" disabled={submitDisabled}>Υποβολή</button>
                                </div>
                            </div>
                        }
                    </form>
                </div>
                <div className="col-12 mt-3">
                    <ValidationAlert pwdMatch={pwdMatch} pwdValid={pwdValid} phoneValid={phoneValid} />
                </div>
            </div>
    );
}
