import React from 'react';
import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';

export default function HashAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'invalid':
        case 'expired':
            return (
                <div className="alert alert-warning">
                    Ο σύνδεσμος ενεργοποίησης λογαριασμού έχει λήξει. Εάν έχετε ήδη ενεργοποιήσει τον λογαριασμό σας, δοκιμάστε να εισέλθετε από 
                    την <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>σελίδα σύνδεσης</Link>. Εάν δεν ενεργοποιήσατε τον λογαριασμό σας θα πρέπει να επικοινωνήσετε εκ νέου με το διαγνωστικό κέντρο.
                </div>
            );
        case 'already-activated':
            return (
                <div className="alert alert-success">
                    Ο λογαρισμός έχει ήδη ενεργοποιηθεί. Για να συνδεθείτε, πατήστε <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>εδώ</Link>.
                </div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}
