import React from 'react';

export default function DelegateLoginAlert({ status }) {
    switch(status) {
        case 'unauthorized':
            return (
                <div className="alert alert-warning">
                    Λάθος συνδυασμός e-mail και κωδικού πρόσβασης.
                </div>
            );
        case 'expired':
            return (
                <div className="alert alert-warning">
                    Η πρόσβασή σας έχει λήξει. Αν θέλετε να ανανεώσετε το δικαίωμα πρόσβασης στον ιατρικό φάκελο του συγκεκριμένου ασθενούς, θα πρέπει να επικοινωνήσετε μαζί του.
                </div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'no-fetch':
        default:
            return <></>;
    }
}
