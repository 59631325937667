import ReactModal from 'react-modal';
import { useModal } from 'hooks/useModal';

export default function UnsettledModal() {

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(180, 180, 180, 0.75)'
        },
        content: {
            position: 'absolute',
            top: '40%',
            left: '30%',
            right: '30%',
            bottom: '38%',
            padding: '30px'
        },
    };

    const modal = useModal();

    return (
        <ReactModal isOpen={modal.unsettledModalOpen} onRequestClose={modal.closeUnsettledModal} style={customStyles} ariaHideApp={false} >
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={{ position: 'fixed', top: '40%', left: '30%' }} onClick={modal.closeUnsettledModal}></button>
            <p>Η εξέταση {modal.examData} δεν έχει ξεχρεωθεί ακόμη. Δεν είναι διαθέσιμα τα αποτελέσματα και το ιστορικό της.</p>
            <div className="d-flex justify-content-center"><button type="button" className="btn btn-primary" onClick={modal.closeUnsettledModal}>Κλείσιμο</button></div>
        </ReactModal>
    )
}