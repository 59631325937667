import { useToast } from 'hooks/useToast';
import { Toast, ToastContainer } from 'react-bootstrap';

// This component returns a container with all toasts generated by the user.
// useToast hook returns an array with toasts and this component dispays them on the right corner of the screen.
export default function PdfToast() {

    const toast = useToast();

    if (!toast?.toastArr) return <></>;
    return (
        <ToastContainer className="me-2 mb-5" position='bottom-end' containerPosition='fixed'>
            {toast.toastArr.map(t => (
                <Toast key={t.id} onClose={() => toast.deleteToast(t.id)}>
                    <Toast.Header>
                        <strong className="me-auto">{t.header}</strong>
                        <small>{t.time}</small>
                    </Toast.Header>
                    <Toast.Body className="me-auto py-0">{t.body}</Toast.Body>
                </Toast>
            ))} 
        </ToastContainer>
    );
}
