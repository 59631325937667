import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

import { LINKS } from 'data/links';
import { useAuth } from 'hooks/useAuth';
import { useTitle } from 'hooks/useTitle';
import { useJsonFetch } from 'hooks/useFetch';
import { getJWTinfo, validJWT } from 'utils/jwt';


import TokenAlert from 'pages/recover/TokenAlert';
import NewPwdForm from 'components/NewPwdForm';
import RecoverAlert from 'pages/recover/RecoverAlert';
import LoadingIndicator from 'components/LoadingIndicator';

export default function RecoverManager() {
    
    useTitle("Ανάκτηση λογαριασμού");
    
    const auth = useAuth();
    const { jwt } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    const [ prepareStatus, setPrepareStatus ] = useState('no-fetch'); 
    const [ recoverStatus, setRecoverStatus ] = useState('no-fetch');

    // This function is invoked when the user submits the form and 
    // generates a request to the recovery endpoint.
    function recoverHandler(pwd, phone) {
        trackPromise(
            fetchFunc(LINKS.AUTH_RECOVER,  {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify({ password: pwd, phone: phone })
            })
            .then(({ msg, status }) => {
                setRecoverStatus(status);
            })
            .catch(() => {
                setRecoverStatus('error');
            })
        , 'recover');
    }

    // This function sets recoverStatus to 'no-fetch' to clear any messages.
    function statusReseter() {
        setRecoverStatus('no-fetch');
    }

    // This hook runs when the page loads and verifies the recovery token.
    // It locally checks if the token has expired. If not, it creates a request to backend API.
    // The API response if the token is valid or if it has been already used.
    useEffect(() => {
        if (!validJWT(jwt, "recover")) { 
            setPrepareStatus('token-expired');
            return;
        }
        trackPromise(
            fetchFunc(LINKS.VERIFY_RECOVERY_TOKEN, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })
            .then(({ msg, status }) => setPrepareStatus(status))
            .catch(() => setPrepareStatus('error'))
        , "validateToken");
    }, [jwt])

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-9">
                <h4 className='mb-4'>Ανάκτηση λογαριασμού</h4>
                <TokenAlert status={prepareStatus} />
                <LoadingIndicator area="validateToken" />
                {(prepareStatus === 'token-valid') && <NewPwdForm email={getJWTinfo(jwt).email} submitCallback={recoverHandler} clearCallback={statusReseter} />}
                <LoadingIndicator area="recover" />
                <RecoverAlert status={recoverStatus} />
            </div>
        </div>
    )

}
