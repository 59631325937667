import { useModal } from 'hooks/useModal';
import PdfButtons from 'pages/results/PdfButtons';
import ExamGroupImage from 'pages/results/ExamGroupImage'; 
import ExamGroupTableRow from 'pages/results/ExamGroupTableRow';
import React, { useEffect, useLayoutEffect, useState } from 'react';

export default function ExamGroupTable({ groupData, groupLayout }) {

    const modal = useModal();

    const [ dataState, setDataState ] = useState({
        rows: [],
        images: [],
        remarks: []
    });

    const [ widths, setWidths ] = useState({
        Column1Width: 100/3, 
        Column2Width: 100/3,
        Column3Width: 100/3
    });

    function fieldPreprocessing(fieldArr) {
        let numericResObj = {}, imageResObj = {};
        for (let f of fieldArr) {
            switch (f['ResultType']) {
                case '7':
                    imageResObj[f['FieldFullName']] = {
                        'bytes': f['Result'],
                        'label': f['FieldFullName'],
                        'height': f['Height'],
                        'width': f['Width']
                    };
                    break;
                case '1':
                case '2':
                    numericResObj[f['FieldName']] = {
                        'value': f['Result'] ?? "",
                        'abnormal': f['AbnormalStatus'] !== '0'
                    };
                    break;
                default:
                    console.error("Unexpected result type: " + f['ResultType'])
                    break;
            }
        }
        return [numericResObj, imageResObj];
    }

    function generateGroupStructure() {
        let rows = [], images = [], remarks = [];
        const data = groupData['Tests'];
        const layout = groupLayout['Tests'];
        Object.keys(data).sort().forEach(key => {
            if (layout[key]['Remarks']) {
                remarks.push(layout[key]['Remarks']);
            }
            let { Fields: dataFields, ...dataRest } = data[key];
            let [ fieldsObj, imagesObj ] = fieldPreprocessing(dataFields);
            
            Object.entries(imagesObj).forEach(([imgKey, imgData]) => {
                images.push(
                    <ExamGroupImage imageData={imgData} key={imgKey} />
                );
            });
            rows.push(
                <ExamGroupTableRow examFields={fieldsObj} examVariousData={dataRest} examLayoutObj={layout[key]} key={`printout-${key}`}/>
            );
        });
        return { rows, images, remarks };
    }
    function calculateColumnWidths() {
        // Calculate column widths as percentages
        let widthTemp = {}, stateTemp = {}
        let totalWidth = 1;
        for (let col of ['Column1Width', 'Column2Width', 'Column3Width']) {
            let colWidth;
            try {
                colWidth = Math.max(parseInt(groupLayout[col]), 0)
            }
            catch {
                colWidth = 0;
            }
            totalWidth += colWidth;
            widthTemp[col] = colWidth;
        }
        for (let col of ['Column1Width', 'Column2Width', 'Column3Width']) {
            let colWidthPercentage = widthTemp[col] / totalWidth * 100
            stateTemp[col] = colWidthPercentage.toFixed(2);
        }
        return stateTemp;
    }

    useEffect(() => {
        setDataState(generateGroupStructure);
    }, [groupData, groupLayout]);

    useLayoutEffect(() => {
        setWidths(calculateColumnWidths)
    }, [groupLayout]);

    return (
        <React.Fragment>
            <div className={`d-flex justify-content-end mb-2 ${parseFloat(widths.Column3Width) > 25.0 && 'btn-print'}`}>
                <PdfButtons 
                    orderId={modal.orderId}
                    printGroupId={groupData.PrintGroupId}
                    printGroupName={groupData.PrintGroupName}
                    isWritable={false}
                />
            </div>
            <div className="group-body fs-7">
                <table className={`${groupLayout['GroupGrid'] === '1' ? "table table-bordered " : ""}exam-layout-table`}>
                    <thead className='exam-layout-header'>
                        <tr>
                            <th style={{width: widths.Column1Width + "%"}}>{groupLayout['Column1Title']?.trim()}</th>
                            <th style={{width: widths.Column2Width + "%"}}>{groupLayout['Column2Title']?.trim()}</th>
                            <th style={{width: widths.Column3Width + "%"}}>{groupLayout['Column3Title']?.trim()}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataState.rows}
                    </tbody>
                </table>
                <p>{groupLayout['GeneralRemarks'] ?? ""}</p>
                {dataState.images}
            </div>
            <div className="group-footer">
                <div className="row">
                    <div className="col-8">
                        {
                            dataState.remarks.length !== 0
                            ? 
                                <React.Fragment>
                                    <h6>Παρατηρήσεις</h6>
                                    {dataState.remarks.map((r,i) => <p key={`remark-${i}`}>{r}</p>)}
                                </React.Fragment>
                            : 
                                <></>
                        }
                    </div>
                    <div className="col-4 text-center" id="signature">
                        {groupLayout['GroupSigner']}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}