import { Buffer } from "buffer";

/**
 * @typedef JWTPayload
 * @property {boolean} fresh
 * @property {number} iat
 * @property {string} jti
 * @property {string} type
 * @property {number} sub
 * @property {number} nbf
 * @property {number} exp
 * @property {string} email
 */

/**
 * @param {string} jwt
 */
function getJWTinfo(jwt) {
    if (jwt == null) return null;
    try {
        const [ header64, payload64, signarute64 ] = jwt.split('.');
        /** @type {JWTPayload} */
        const payload = JSON.parse(Buffer.from(payload64, 'base64').toString());
        return payload;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * @param {string} jwt
 * @param {string} isOfType
 */
function validJWT(jwt, isOfType) {

    const payload = getJWTinfo(jwt);
    if (payload == null) return false;
    
    // Clock skew warning: payload.nbf might be smaller than Date.now() due to clock skew between different machines.
    return (payload.exp * 1000 > Date.now()) && (payload.type === isOfType);
}

export { getJWTinfo, validJWT }