import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { useJsonFetch } from 'hooks/useFetch';

import TableAlert from 'pages/delegate/TableAlert';
import LoadingIndicator from 'components/LoadingIndicator';

const fontSizePx = (isMobile) ? "11px" : "14px";
const btnFontSizePx = "11px"

export default function DelegationPage() {
    
    const auth = useAuth();
    const fetchFunc = useJsonFetch();
    const { promiseInProgress } = usePromiseTracker();
    const [ fetchStatus, setFetchStatus ] = useState({ operation: '', status: 'no-fetch' });

    const [ delegationData, setDelegationData ] = useState([]);
    const [ shouldUpdate, setShouldUpdate ] = useState(true);
    
    function revokeDelegate(delegationId) {
        setFetchStatus({});
        trackPromise(
            fetchFunc(LINKS.DELEGATE_REVOKE, {
                method: 'POST',
                body: JSON.stringify({
                    delegationId: delegationId
                })
            })
            .then(({ status }) => {
                setFetchStatus({ operation: 'revoke', status: status });
                setShouldUpdate(true);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus({ operation: 'revoke', status: 'error' });
            })
        );
    }

    function remindDelegate(delegationId) {
        setFetchStatus({});
        trackPromise(
            fetchFunc(LINKS.DELEGATE_REMIND, {
                method: 'POST',
                body: JSON.stringify({
                    delegationId: delegationId,
                    userDescr: auth.info.name
                })
            })
            .then(({ status, data }) => {
                setFetchStatus({ operation: 'remind', status: status, pwd: data.password, nextRemindIn: data.minutes_to_next_remind });
            })
            .catch(e => {
                console.error(e);
                setFetchStatus({ operation: 'remind', status: 'error' })
            })
        )
    }

    function reactivateDelegation(delegationData) {
        setFetchStatus({});
        trackPromise(
            fetchFunc(LINKS.DELEGATE_ADD, {
                method: 'POST',
                body: JSON.stringify({
                    userDescr: auth.info.name,
                    delegateEmail: delegationData.email,
                    delegateDescr: delegationData.descr,
                    duration: 2
                })
            })
            .then(({ status, pwd }) => {
                setFetchStatus({ operation: 'reactivate', status, pwd });
                setShouldUpdate(true);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus({ operation: 'reactivate', status: 'error' });
            })
        )
    }

    useEffect(() => {
        if (!shouldUpdate) return;
        setShouldUpdate(false);
        trackPromise(
            fetchFunc(LINKS.DELEGATE_LIST, {
                method: 'GET'
            })
            .then(({ status, data }) => {
                if (status === 'success') setDelegationData(data);
                else setFetchStatus({ operation: 'list', status });
            })
            .catch(e => {
                console.error(e);
                setFetchStatus({ operation: 'list', status: 'error' })
            })
        )
    }, [shouldUpdate])
    
    function ActionBtn({ delegationData }) {
        if (delegationData?.exp_status === 'active') {
            return (
                <div className='d-grid gap-1'>
                    <button 
                        type="button" 
                        className="btn btn-primary py-0 px-1" 
                        style={{ fontSize: btnFontSizePx }} 
                        disabled={promiseInProgress} 
                        onClick={() => revokeDelegate(delegationData.id)}
                    >
                        Ανάκληση
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-primary py-0 px-1" 
                        style={{ fontSize: btnFontSizePx }} 
                        disabled={promiseInProgress} 
                        onClick={() => remindDelegate(delegationData.id)}
                    >
                        Υπενθύμιση
                    </button>
                </div>
            )
        } else {
            return (
                <div className="d-grid">
                    <button 
                        type="button" 
                        className="btn btn-primary py-0 px-1" 
                        style={{ fontSize: btnFontSizePx }} 
                        disabled={promiseInProgress} 
                        onClick={() => reactivateDelegation(delegationData)}
                    >
                        Ενεργοποίηση
                    </button>
                </div>
            )
        }
    }

    function DelegationTable({ delegationList }) {
        if (!delegationList) return <></>;
        if (isMobile) return (
            <table className='table table-sm align-middle' style={{ fontSize: fontSizePx }}>
                <thead>
                    <tr>
                        <th scope='col' style={{ width: '55%' }}>Όνομα</th>
                        <th scope='col' style={{ width: '25%' }}>Λήξη</th>
                        <th scope='col' style={{ width: '20%' }}>Ενέργεια</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        delegationData.map(delegation => (
                            <tr key={delegation.id}>
                                <td className='text-break'>{`${delegation.descr} (${delegation.email})`}</td>
                                <td className={`${delegation.exp_status === 'active' ? 'text-success' : 'text-danger'} text-break`}>{delegation.exp_date}</td>
                                <td><ActionBtn delegationData={delegation} /></td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        );
        else return (
            <table className='table table-sm align-middle' style={{ fontSize: fontSizePx }}>
                <thead>
                    <tr>
                        <th scope='col' style={{ width: '35%' }}>Όνομα</th>
                        <th scope='col' style={{ width: '32%' }}>E-mail</th>
                        <th scope='col' style={{ width: '15%' }}>Λήξη</th>
                        <th scope='col' style={{ width: '18%' }}>Ενέργεια</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        delegationData.map(delegation => (
                            <tr key={delegation.id}>
                                <td>{delegation.descr}</td>
                                <td className='text-break'>{delegation.email}</td>
                                <td className={`${delegation.exp_status === 'active' ? 'text-success' : 'text-danger'}`}>{delegation.exp_date}</td>
                                <td><ActionBtn delegationData={delegation} /></td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <h4>Παραχώρηση πρόσβασης</h4>
                <p className='my-3'>Στον παρακάτω πίνακα φαίνονται τα άτομα στα οποία έχετε δώσει δικαίωμα προβολής του ιατρικού σας ιστορικού, όπως αυτό εμφανίζεται στο Diagnosis Portal.</p>
                <DelegationTable delegationList={delegationData} />
                <div className="d-flex justify-content-center my-4">
                    <Link className="btn btn-primary py-1" style={{ fontSize: fontSizePx }} to={ROUTES.DELEGATE_NEW}>Νέα πρόσβαση</Link>
                </div>
                <LoadingIndicator />
                <TableAlert statusObj={fetchStatus} />
            </div>
        </div>
    );
}
