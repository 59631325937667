import { LINKS } from 'data/links';
import { useJsonFetch } from 'hooks/useFetch';

import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { trackPromise } from 'react-promise-tracker';

function RegistrationAlert({ status }) {
    switch(status) {
        case 'success':
            return (
                <div className="alert alert-success">
                    Ο λογαριασμός δημιουργήθηκε επιτυχώς. Θα λάβετε ένα email με οδηγίες για την ενεργοποίηση του.
                </div>
            );
        case 'invalid-domain':
        case 'invalid-syntax':
            return (
                <div className="alert alert-warning">
                    Η διεύθυνση e-mail που καταχωρήσατε δεν είναι έγκυρη. Παρακαλώ διορθώστε την και προσπαθήστε ξανά.
                </div>
            )
        case 'wrong-email':
            return (
                <div className="alert alert-warning">
                    Η διεύθυνση e-mail που καταχωρήσατε δεν αντιστοιχεί σε αυτή που έχει καταχωρηθεί στην καρτέλα σας. Παρακαλώ δοκιμάστε ξανά.
                </div>
            );
        case 'conflict-email':
            return (
                <div className="alert alert-warning">
                    Η διεύθυνση e-mail χρησιμοποιείται από άλλον χρήστη. Η εγγραφή σας μπορεί να ολοκληρωθεί μόνο με επίσκεψη στο διαγνωστικό κέντρο για ταυτοποίηση στοιχείων.
                </div>
            );
        case 'already-registered':
            return (
                <div className="alert alert-warning">
                    Ο λογαριασμός έχει ήδη δημιουργηθεί και έχετε λάβει ένα email με περισσότερες οδηγίες. Παρακάλω ελέγξτε και στον φάκελο ανεπιθύμητης αλληλογραφίας (spam).
                </div>
            );
        case 'max-tries-exceeded':
            return (
                <div className="alert alert-danger">
                    Η φόρμα έχει απενεργοποιηθεί, γιατί έγιναν πολλαπλές αποτυχημένες προσπάθειες. Η εγγραφή σας μπορεί να ολοκληρωθεί μόνο με επίσκεψη στο διαγνωστικό κέντρο για ταυτοποίηση στοιχείων.
                </div>
            );
        case 'hash-invalid':
            return (
                <div className="alert alert-danger">
                    Ο σύνδεσμος έχει λήξει.
                </div>
            );
        case 'error':
        case 'email-error':
        case 'archive-error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}

export default function RegistrationForm({ data, hashedId }) {

    const [ email, setEmail ] = useState("");
    const fetchFunc = useJsonFetch({ secure: false });
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    function submitHandler(e) {
        e.preventDefault();
        setSubmitDisabled(true);
        trackPromise(
            fetchFunc(LINKS.REGISTER_FINALIZE, {
                method: 'POST',
                body: JSON.stringify({ hashedId, email })
            })
            .then(({ status }) => {
                setFetchStatus(status);
                if (['wrong-email', 'invalid-domain', 'invalid-syntax', 'error'].includes(status)) setSubmitDisabled(false);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
                setSubmitDisabled(false);
            })
        , 'register');
    }

    if (!data) return <></>;
    if (isMobile) return (
        <div className="row mx-0">
            <div className="col-12 border border-2 rounded-2 mb-3 px-3 py-2">
                <form className="row g-2 fs-7" onSubmit={submitHandler}>
                    <div className="col-12">
                        <label htmlFor="name" className="form-label mb-0">Ονοματεπώνυμο</label>
                        <input type="text" className="form-control fs-7" id="name" value={`${data.firstName ?? ""} ${data.lastName ?? ""}`} disabled />
                    </div>
                    <div className="col-12">
                        <label htmlFor="lastVisit" className="form-label mb-0">Τελευταία επίσκεψη</label>
                        <input type="text" className="form-control fs-7" id="lastVisit" value={data.lastVisit} disabled />
                    </div>
                    <div className="col-12">
                        <label htmlFor="phone" className="form-label mb-0">Κινητό τηλέφωνο</label>
                        <input type="text" className="form-control fs-7" id="phone" value={data.mobilePhone} disabled />
                    </div>
                    <div className="col-12">
                        <label htmlFor="email" className="form-label mb-0">E-mail</label>
                        <input type="email" className="form-control fs-7" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={submitDisabled}>Υποβολή</button>
                    </div>
                </form>
            </div>
            <RegistrationAlert status={fetchStatus} />
        </div>
    )
    return (
        <div className="row mx-0">
            <div className="col-12 border border-2 rounded-2 mb-3 p-4">
                <form className="row g-3" onSubmit={submitHandler}>
                    <div className="col-6">
                        <label htmlFor="name" className="form-label">Ονοματεπώνυμο</label>
                        <input type="text" className="form-control" id="name" value={`${data.firstName ?? ""} ${data.lastName ?? ""}`} disabled />
                    </div>
                    <div className="col-6">
                        <label htmlFor="lastVisit" className="form-label">Τελευταία επίσκεψη</label>
                        <input type="text" className="form-control" id="lastVisit" value={data.lastVisit} disabled />
                    </div>
                    <div className="col-6">
                        <label htmlFor="phone" className="form-label">Κινητό τηλέφωνο</label>
                        <input type="text" className="form-control" id="phone" value={data.mobilePhone} disabled />
                    </div>
                    <div className="col-6">
                        <label htmlFor="email" className="form-label">E-mail</label>
                        <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary" disabled={submitDisabled}>Υποβολή</button>
                    </div>
                </form>
            </div>
            <RegistrationAlert status={fetchStatus} />
        </div>
    );
}
