import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import { LINKS } from "data/links";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useFetch } from "hooks/useFetch";
import { useToggleRef } from "hooks/useToggle";
import DelegateDicom from "./DelegateDicom";

const headerSize = (isMobile) ? '10px' : '14px';
const tagSize = (isMobile) ? '7px' : '10px';

export function OrdersDatesTable({ show, rows }) {
    
    const ref = useToggleRef(show);
    const auth = useAuth();
    const modal = useModal();
    const toast = useToast();
    const fetchFunc = useFetch({ secure: true });

    // There are four categories of exam tags on the web version:
    // 1) Non-clickable grey tags for exams with result but no history
    // 2) Clickable grey tags for exams with result and history (they open history modal)
    // 3) Clickable red tags for exams without results (they open info modal)
    // 4) Clickable orange tags for unsettled exams (they open unsettled modal)
    // On the mobile version, exam tags follow the same color rules, but are not clickable.
    function ExamTags({ examInfo, tagKey, orderId }) {
        if (isMobile) {
            if (examInfo.IsSettled) return (
                <span key={`${orderId}-${tagKey}`} 
                    className={`btn btn-sm me-1 p-1 ${examInfo.HasResult ? 'btn-secondary' : 'btn-danger'}`}
                    style={{ 'fontSize': tagSize, 'overflowWrap': 'break-word', 'marginBottom': '2px' }}
                >
                    {examInfo.ExamDescr}
                </span>
            )
            else return (
                <span key={`${orderId}-${tagKey}`} 
                    className='btn btn-sm me-1 text-nowrap btn-warning'
                    style={{ 'fontSize': tagSize, 'overflowWrap': 'break-word', 'marginBottom': '2px' }}
                >
                    {examInfo.ExamDescr}
                </span>
            )
        }
        else if (!examInfo.IsSettled) return (
            <button key={`${orderId}-${tagKey}`} 
                className="btn btn-warning btn-sm me-1 p-1" 
                style={{ 'fontSize': tagSize, 'pointerEvents': 'auto', 'marginBottom': '2px' }} 
                title='Η εξέταση δεν έχει ξεχρεωθεί. Πρέπει να την ξεχρεώσετε για να δείτε τα αποτελέσματα.'
                onClick={() => modal.openUnsettledModal(examInfo.ExamDescr)}
            >
                {examInfo.ExamDescr}
            </button>
        )
        else if (examInfo.HasResult && !examInfo.HasHistory) return (
            <button key={`${orderId}-${tagKey}`} 
                className="btn btn-secondary btn-sm me-1 p-1" 
                style={{ 'fontSize': tagSize, 'pointerEvents': 'auto', 'marginBottom': '2px' }} 
                title='Δεν έχει ιστορικότητα η εξέταση. Πατήστε το κουμπί Αποτελέσματα.'
                disabled={true}
            >
                {examInfo.ExamDescr}
            </button>
        ); 
        else if (examInfo.HasResult && examInfo.HasHistory) return(
            <button key={`${orderId}-${tagKey}`} 
                className="btn btn-secondary btn-sm me-1 p-1" 
                style={{ 'fontSize': tagSize, 'marginBottom': '2px' }} 
                onClick={() => modal.openHistoryModal(examInfo)} 
                disabled={false} 
                title='Ιστορικότητα μετρήσεων'
            >
                {examInfo.ExamDescr}
            </button>
        );
        else if (!examInfo.HasResult) return (
            <button key={`${orderId}-${tagKey}`} 
                className="btn btn-danger btn-sm me-1 p-1" 
                style={{ 'fontSize': tagSize, 'marginBottom': '2px' }} 
                onClick={() => modal.openInfoModal(examInfo, orderId)}
                title='Εξέταση χωρίς αποτέλεσμα'
                disabled={false}
            >
                {examInfo.ExamDescr}
            </button>
        )
    }

    // This function queries the API for the results PDF of a specific print group.
    // If there is a PDF, the file downloads automatically. If not, it makes a generation request.
    function downloadPdfMerged(orderId, orderDate, mode, setBtnDisabled) {
        setBtnDisabled(true);
        fetchFunc(LINKS.GET_PDF_MERGED, {
            method: 'POST',
            responseType: 'blob',
            body: JSON.stringify({
                order_id: orderId,
                pa_code: auth.user.userId
            })
        })
        .then(resp => {
            // If response status is 200, the response contains a blob. Else a JSON object.
            if (resp.status === 200) {
                return resp.blob().then(respData => ({ status: resp.status, data: respData }))
            }
            else {
                return resp.json().then(respData => ({ status: resp.status, data: respData }))
            }
        })
        .then(({ status, data }) => {
            setBtnDisabled(false);
            switch (status) {
                // Status = 200 -> PDF is ready for download.
                case 200:
                    switch (mode) {
                        case 'show':
                            const file = new Blob([data], {
                                type: 'application/pdf'
                            });
                            const fileURL = window.URL.createObjectURL(file);
                            window.open(fileURL);
                            window.URL.revokeObjectURL(fileURL);
                            break;
                        case 'download':
                            const blobURL = window.URL.createObjectURL(data);
                            const a = document.createElement("a")
                            document.body.appendChild(a);
                            a.href = blobURL;
                            a.download = `${orderId}.pdf`;
                            a.click()
                            a.remove();
                            window.URL.revokeObjectURL(blobURL); 
                            break;  
                        default:
                            break;
                    }
                    break;
                // Status = 210 -> Something went wrong, as requested exams = 0.
                case 210:
                    toast.addToast("Νέο αίτημα PDF", `Καταχωρήθηκε αίτημα δημιουργίας του PDF αποτελεσμάτων για την επίσκεψη στις ${orderDate}. (exams = 0)`);
                    break;                
                // Status = 220 -> PDF request is pending.
                // Status = 221 -> A new PDF request has been made.
                case 220:
                case 221:
                    toast.addToast("Αναμονή για PDF", `Έχει καταχωρηθεί αίτημα δημιουργίας PDF για την επίσκεψη στις ${orderDate}. Παρακαλώ ελέγξτε πάλι σε λίγο.`);
                    break;
                // Status = 230 -> PDF request is pending, but PDF generation service is temporarily down.
                case 230:
                    toast.addToast("Αναμονή για PDF", `Έχει καταχωρηθεί αίτημα δημιουργίας PDF για την επίσκεψη στις ${orderDate}. Παρακαλώ ελέγξτε πάλι μετά τις ${data.not_before}.`);
                    break;
                // Status = 500 -> An error has occurred.
                case 500:
                    toast.addToast("Σφάλμα", "Παρουσιάστηκε σφάλμα κατά την λήψη του PDF. Παρακαλώ δοκιμάστε αργότερα.");
                    break;
                default:
                    break;
            }
        })
        .catch(e => {
            console.error(e);
            setBtnDisabled(false);
        })
    }

    // On browser version, there are three different result buttons.
    // On mobile version, there is just one download button.
    // For delegates, there is also a RIS Info button. This button appears only if some exams do not have history, 
    // since RIS exams never have history. It appears even if the results are not yet ready.
    function ResultsBtn({ rowInfo }) {
        const [ btnDisabled, setBtnDisabled ] = useState(false);
        if (rowInfo.tags.every(t => !t.IsSettled)) return <span style={{ fontSize: `${isMobile ? '13px': '16px'}`}}>Οφείλεται</span>;
        else if (rowInfo.tags.every(t => !t.HasResult)) {
            if (auth.user.accessLevel === 'delegate' && rowInfo.tags.some(t => !t.HasHistory)) {
                return <DelegateDicom orderId={rowInfo.visit} />
            }
            else return <span style={{ fontSize: `${isMobile ? '13px': '16px'}`}}>Αναμένονται</span>;;
        }
        else if (isMobile) return (
            <div className="d-grid">
                <button 
                    type="button" 
                    disabled={btnDisabled} 
                    style={{ fontSize: '9px' }} 
                    className="btn btn-primary btn-sm p-0 text-center"
                    onClick={() => downloadPdfMerged(rowInfo.visit, rowInfo.date, 'show', setBtnDisabled)}
                >
                    Λήψη PDF
                    { btnDisabled && <span className="spinner-grow ms-1" style={{ width: '0.6rem', height: '0.6rem' }} role="status" /> }
                </button>
                {rowInfo.tags.some(t => !t.HasHistory) && <DelegateDicom orderId={rowInfo.visit} />}
            </div>
        )
        else return (
            <span className="d-flex">
                <button className="btn btn-primary btn-sm py-0 px-1" onClick={() => modal.openResultsModal(rowInfo.visit, rowInfo.date)}>
                    <i className="bi bi-box-arrow-up-right" title="Προβολή αποτελεσμάτων"></i>
                </button>
                <button className="btn btn-primary py-0 px-1 ms-2" disabled={btnDisabled} onClick={() => downloadPdfMerged(rowInfo.visit, rowInfo.date, 'show', setBtnDisabled)}>
                    <i className="bi bi-filetype-pdf" title='Προβολή PDF αποτελεσμάτων'></i>
                </button>
                <button className="btn btn-primary py-0 px-1 ms-2" disabled={btnDisabled} onClick={() => downloadPdfMerged(rowInfo.visit, rowInfo.date, 'download', setBtnDisabled)}>
                    <i className="bi bi-download" title='Λήψη PDF αποτελεσμάτων'></i>
                </button>
                {rowInfo.tags.some(t => !t.HasHistory) && <DelegateDicom orderId={rowInfo.visit} />}
                { btnDisabled && <div className="spinner-border spinner-border-sm text-primary ms-2" role="status" /> }
            </span>
        )
    }

    return (
        <table className="table table-sm mt-2 table-hover collapse fade fs-7" ref={ref}>
            <thead className="table-primary">
                <tr>
                    <th style={{ width: '9%', fontSize: headerSize }}>Ημερομηνία</th>
                    <th style={{ width: '15%', fontSize: headerSize }}>Aποτελέσματα</th>
                    <th style={{ width: '76%', fontSize: headerSize }}>Εξετάσεις</th>
                </tr>
            </thead>
            <tbody>
                {
                    rows.map( r => 
                        <tr key={r.visit}>
                            <td style={{ fontSize: isMobile ? '11px' : '14px' }}>{r.date}</td>
                            <td>
                                <ResultsBtn rowInfo={r} />
                            </td>
                            <td>
                                { r.tags.map((t,i) => <ExamTags key={i} examInfo={t} tagKey={i} orderId={r.visit}/>) }
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    );
}