import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { trackPromise } from "react-promise-tracker";

import { LINKS } from "data/links";
import { useJsonFetch } from "hooks/useFetch";
import LoadingIndicator, { LoadingIndicatorInline, LoadingIndicatorSm } from "components/LoadingIndicator";

import ActivateAlert from "pages/activateHash/ActivateAlert";

export default function ActivationForm({ activationCallback }) {

    const [ email, setEmail ] = useState('');
    const [ pwdFirstTry, setPwdFirstTry ] = useState('');
    const [ pwdSecondTry, setPwdSecondTry ] = useState('');
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    const [ pwdMatch, setPwdMatch ] = useState(true);
    const [ pwdValid, setPwdValid ] = useState(true);
    const [ pwdPolicy, setPwdPolicy ] = useState(undefined);

    const [ activationStatus, setActivationStatus ] = useState('no-fetch');

    const fetchFunc = useJsonFetch({ secure: false });

    // This hook runs where the form loads and fetches the password policy.
    useEffect(() => {
        fetchFunc(LINKS.PWD_POLICY, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        })
        .then(({ policy }) => setPwdPolicy(policy))
        .catch(console.error)
    }, []);

    // This function runs when the user submits the form. 
    // It verifies the user-provided new password and creates a request to activation endpoint.
    function submitHandler(e) {

        e.preventDefault();
        setSubmitDisabled(true); // Disable submit button
        setActivationStatus('no-fetch'); // Clear any previous error messages

        // Check if passwords provided match
        const pwdMatchTmp = pwdFirstTry === pwdSecondTry; // A temp variable is used because setState is not synchronous
        setPwdMatch(pwdMatchTmp);

        // Check if password complies with the password policy
        const regex = new RegExp(`^(?=.*?[a-zA-Z])(?=(.*?\\d){${pwdPolicy.min_numeric}})[A-Za-z0-9#$@!%&*?]{${pwdPolicy.min_chars},${pwdPolicy.max_chars}}$`);
        const pwdValidTmp = regex.test(pwdFirstTry); // A temp variable is used because setState is not synchronous
        setPwdValid(pwdValidTmp);

        if (pwdMatchTmp && pwdValidTmp) {
            trackPromise(
                fetchFunc(LINKS.ACTIVATE_HASH_FINALIZE, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        email: email,
                        password: pwdFirstTry
                    })
                })
                .then(({ status, tokens }) => {
                    setActivationStatus(status);
                    activationCallback(status, tokens); // Let parent component know about fetch status and tokens
                    if (['error', 'email-not-verified'].includes(status)) setSubmitDisabled(false); // Enable form submit button
                })
                .catch(() => {
                    setActivationStatus('error');
                    activationCallback('error', null);
                    setSubmitDisabled(false);
                })
            , 'activate');
        }
        else {
            setSubmitDisabled(false); // If password is not valid, enable form submit button
        }
    }

    function pwdInputHandler(e, setter) {
        setPwdMatch(true);
        setPwdValid(true);
        setter(e.target.value);
    }

    const formPadding = isMobile ? "px-3 py-2" : "p-4";
    const formStyling = isMobile ? "row g-2 fs-7" : "row g-3"
    return (
        <div className="row mx-0">
            <div className={`col-12 border border-2 rounded-2 mb-3 ${formPadding}`}>
                <form className={formStyling} onSubmit={submitHandler}>
                    <div className="col-md-12">
                        <label htmlFor="email" className="form-label-sm">Email</label>
                        <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="pwdFirstTry" className="form-label-sm">Kωδικός πρόσβασης</label>
                        <input type="password" 
                            id="pwdFirstTry" 
                            className={`form-control ${!(pwdValid && pwdMatch) && 'is-invalid'}`} 
                            value={pwdFirstTry} 
                            onChange={e => pwdInputHandler(e, setPwdFirstTry)} required 
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="pwdSecondTry" className="form-label-sm">Κωδικός πρόσβασης ξανά</label>
                        <input type="password" 
                            id="pwdSecondTry" 
                            className={`form-control ${!(pwdValid && pwdMatch) && 'is-invalid'}`} 
                            value={pwdSecondTry} 
                            onChange={e => pwdInputHandler(e, setPwdSecondTry)} required 
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <LoadingIndicatorSm area='activate' />
                        <button type="submit" className={`btn btn-primary ms-3 ${isMobile && "fs-7"}`} disabled={submitDisabled}>Υποβολή</button>
                    </div>
                </form>
            </div>
            <ValidationAlert pwdMatch={pwdMatch} pwdValid={pwdValid} />
            <ActivateAlert status={activationStatus} />
            <PwdPolicyDisplay pwdPolicy={pwdPolicy} />
        </div>
    );
}

function PwdPolicyDisplay({ pwdPolicy }) {
    return (
        <div className={isMobile && "fs-7"}>
            <b className='mb-0'>Προϋποθέσεις ασφαλείας κωδικού:</b>
            <ul className='px-3 mb-0'>
                <li>Να αποτελείται από λατινικούς χαρακτήρες, αριθμούς ή ειδικά σύμβολα.</li>
                <li>Να έχει μήκος {pwdPolicy?.min_chars} έως {pwdPolicy?.max_chars} χαρακτήρες.</li>
                <li>Ελάχιστο πλήθος χαρακτήρων: 1</li>
                {(pwdPolicy?.min_numeric > 0) && <li>Ελάχιστος πλήθος αριθμών: {pwdPolicy?.min_numeric}</li>}
            </ul>
        </div>
    )
}

function ValidationAlert({ pwdMatch, pwdValid }) {
    const alertMobileStyle = isMobile && "p-2 fs-7"
    if (!pwdMatch) return (
        <div className={`alert alert-danger ${alertMobileStyle}`}>
            Ο κωδικός πρόσβασης δεν είναι ίδιος στα δύο πεδία.
        </div>
    )
    else if (!pwdValid) return (
        <div className={`alert alert-danger ${alertMobileStyle}`}>
            Ο κωδικός πρόσβασης δεν πληροί τις προϋποθέσεις ασφαλείας.
        </div>
    )
    else return <></>;
}