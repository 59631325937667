import React from 'react';
import ExamGroupRIS from 'pages/results/ExamGroupRIS';
import ExamGroupTable from 'pages/results/ExamGroupTable';
import ExamGroupDocument from 'pages/results/ExamGroupDocument';

export default function ExamGroupSwitch(props) {
    switch (props.groupLayout["GroupType"]) {
        case "LAB":
            return <ExamGroupTable {...props} />;
        case "DOC":
            return <ExamGroupDocument groupData={props.groupData} />;
        case "RIS":
            return <ExamGroupRIS {...props} />;
        default:
            return (
                <div className="alert alert-danger">
                    Δεν είναι δυνατή η απεικόνιση των αποτελεσμάτων.
                </div>
            );
    }
}