import React from 'react';

export default function ExamGroupImage(props) {
    
    const regex = /^\^(\w+)\^(\w+)\^(\w+)\^(\S+)/;
    const splitStr = props.imageData.bytes.split(regex);
    const imageType = splitStr[2].toLowerCase();
    const imageEncoding = splitStr[3].toLowerCase();
    const imagePixels = splitStr[4];

    return (
        <div>
            <img 
                src={`data:image/${imageType};${imageEncoding},${imagePixels}`}
                style={{ width: props.imageData.width, height: props.imageData.height }} 
                alt={props.imageData.label}
            />
            <small>{props.imageData.label}</small>
        </div>
    )
}