import React from 'react';

export default function ForgotAlert({ status }) {
    switch (status) {
        case 'success':
            return (
                <div className="alert alert-success">
                    Έχει σταλεί email στην παραπάνω διεύθυνση. Παρακαλώ ελέγξτε και την ανεπιθύμητη αλληλογραφία (spam) και ακολουθήστε τις οδηγίες του κειμένου.
                </div>
            );
        case 'not-exists':
            return (
                <div className="alert alert-warning">
                    Δεν αντιστοιχεί λογαριασμός σε αυτή την διεύθυνση email. Βεβαιωθείτε ότι έχετε ενεργοποιήσει τον λογαριασμό σας.
                </div>
            );
        case 'token-error':
            return (
                <div className="alert alert-warning">
                    Συνέβη απροσδόκητο σφάλμα. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'mail-error':
            return (
                <div className="alert alert-warning">
                    Δεν ήταν εφικτή η αποστολή του email ανάκτησης. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'err':
            return (
                <div className="alert alert-warning">
                    Παρακαλώ προσπαθήστε πάλι.
                </div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}
