import React from 'react';

export default function ResultsAlert({ status }) {
    switch (status) {
        case 'success':
            return <></>;
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            )
        case 'layout-error':
        case 'results-error':
            return (
                <div className="alert alert-danger">
                    Συνέβη σφάλμα κατά την επεξεργασία των δεδομένων. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            )
        default:
            return <></>;
    }
}
