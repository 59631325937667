import { LINKS } from 'data/links';
import { useJsonFetch } from 'hooks/useFetch';
import { useState, useRef, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';

import ForgotAlert from 'pages/forgot/ForgotAlert';
import LoadingIndicator from 'components/LoadingIndicator';

export default function ForgotForm() {

    const [ email, setEmail ] = useState('');
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    // Honeypot variables
    const startTime = useRef(performance.now());
    const [ field1, setField1 ] = useState('');
    const [ field2, setField2 ] = useState('');

    // Timer data
    let timer;
    const [ count, setCount ] = useState(0);
    const [ enableSubmit, setEnableSubmit ] = useState(false);

    const fetchFunc = useJsonFetch({ secure: false });

    // This hook adds a delay before enabling the submit button. 
    // enableSubmit variable acts as a flag indicating that submit button should be activated.
    // The submit button is enabled after 5 * 100 = 500 ms.
    useEffect(() => {
        if (!enableSubmit) return;
        timer = !timer && setInterval(() => {
            setCount(prevCount => prevCount + 1)
        }, 100);
        if (count === 5) {
            setCount(0);
            clearInterval(timer);
            setEnableSubmit(false);
            setSubmitDisabled(false);
        }
        return () => clearInterval(timer);
    }, [enableSubmit, count]);

    // This submit function invokes a forgot request to the the backend. Apart from the email,
    // honeypot variables and time spent count are included in the request body, so that backend can distinguish spam requests.
    // When the request fails, the submit button is enabled with a short delay.
    function sendRecoveryEmail(e) {
        e.preventDefault();
        setSubmitDisabled(true); // Disable submit button to prevent multiple requests.
        setFetchStatus('no-fetch'); // Clear any error messages.
        trackPromise(
            fetchFunc(LINKS.AUTH_FORGOT, {
                method: 'POST',
                body: JSON.stringify({ 
                    email: email,
                    honeypot: { value1: field1, value2: field2 },
                    timeSpent: Math.ceil((performance.now() - startTime.current) / 1000)
                })
            })
            .then(({ msg, status }) => {
                setFetchStatus(status);
                if (status !== 'success') setEnableSubmit(true);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
                setEnableSubmit(true);
            })
        , 'forgot');
    }

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <h4 className='mb-4'>Επαναφορά λογαριασμού</h4>
                <p>Για να ανακτήσετε την πρόσβαση στον λογαριασμό σας, συμπληρώστε την διέυθυνση του email σας και πατήστε Υποβολή. Θα σας σταλεί ένα email με οδηγίες.</p>
                <div className="row">
                    <div className="col-12 border border-2 rounded-2 p-4">
                        <form className="row g-3" onSubmit={sendRecoveryEmail}>
                            <div className="col-12">
                                <label htmlFor="email268" className="form-label-sm">Email</label>
                                <input type="email" className="form-control" id="email268" value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>
                            <label htmlFor="pp147" className="honeyfield"></label>
                            <input type="text" className="honeyfield" autoComplete="new-password" tabIndex="-1" id="pp147" value={field1} onChange={e => setField1(e.target.value)} />
                            <label htmlFor="pp150" className="honeyfield"></label>
                            <input type="text" className="honeyfield" autoComplete="new-password" tabIndex="-1" id="pp150" value={field2} onChange={e => setField2(e.target.value)} />
                            <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-primary" disabled={submitDisabled}>Υποβολή</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row mt-3">
                    <LoadingIndicator area='forgot' />
                    <ForgotAlert status={fetchStatus} />
                </div>
            </div>
        </div>
    );
}
