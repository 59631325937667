import React from 'react';

export default function LoginAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'auth-success':
            return <></>;
        case 'not-exists':
            return (
                <div className="alert alert-warning">Δεν αντιστοιχεί εγγεγραμμένος χρήστης στην διεύθυνση e-mail.</div>
            );
        case 'invalidated':
            return (
                <div className="alert alert-warning">Ο λογαριασμός έχει τεθεί σε αναστολή. Παρακαλώ επικοινωνήστε με το διαγνωστικό κέντρο.</div>
            );
        case 'not-activated':
            return (
                <div className="alert alert-warning">Ο λογαριασμός δεν έχει ενεργοποιηθεί. Παρακαλώ ελέγξτε τα email σας και ακολουθήστε τον σύνδεσμο ενεργοποίησης του λογαριασμού σας.</div>
            );
        case 'auth-failed':
            return (
                <div className="alert alert-warning">Λάθος συνδυασμός email και κωδικού πρόσβασης.</div>
            );
        case 'err':
            return (
                <div className="alert alert-warning">Παρακαλώ προσπαθήστε πάλι.</div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.</div>
            )
        default:
            return <></>;
    }
}
