import React from 'react';

export default function DelegationAlert({ status, pwd }) {
    switch(status) {
        case 'success':
            return (
                <div className="alert alert-success">
                    Έχει σταλεί ένα e-mail με οδηγίες στην διεύθυνση που δηλώσατε. O κωδικός πρόσβασης στο Diagnosis Portal είναι: <b>{pwd}</b>
                </div>
            );
        case 'invalid-syntax':
            return (
                <div className="alert alert-warning">
                    Η διεύθυνση e-mail δεν είναι συντακτικά σωστή. Διορθώστε την διεύθυνση και προσπαθήστε πάλι.
                </div>
            );
        case 'invalid-domain':
            return (
                <div className="alert alert-warning">
                    Η διεύθυνση e-mail δεν είναι έγκυρη. Διορθώστε την διεύθυνση μετά το '@' και προσπαθήστε πάλι.
                </div>
            )
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε πάλι αργότερα.
                </div>
            );
        case 'no-fetch':
        default:
            return <></>;
    }
}
