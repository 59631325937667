import ReactModal from 'react-modal';
import { trackPromise } from 'react-promise-tracker';
import React, { useState, useEffect, useMemo, useRef } from 'react';

import { LINKS } from 'data/links';
import { useAuth } from 'hooks/useAuth';
import { useModal } from 'hooks/useModal';
import { useJsonFetch } from 'hooks/useFetch';
import { LoadingIndicatorSm } from 'components/LoadingIndicator';

import FieldSelect from 'pages/history/FieldSelect';
import HistoryTable from 'pages/history/HistoryTable';
import HistoryChart from 'pages/history/HistoryChart';
import HistoryAlert from 'pages/history/HistoryAlert';
import SubexamSelect from 'pages/history/SubexamSelect';
import { appendStyle, generateIframe, generateWindow } from 'utils/print';

export default function HistoryModal() {

    const fetchFunc = useJsonFetch({ secure: true });
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');
    const [ subexamData, setSubexamData ] = useState(undefined);
    const [ historyData, setHistoryData ] = useState(undefined);
    const [ selectedSubexam, setSelectedSubexam ] = useState(undefined);
    const [ selectedField, setSelectedField ] = useState(undefined);
    
    const chartRef = useRef(undefined);
    const printObjRef = useRef(undefined);
    const [ readyToPrint, setReadyToPrint ] = useState(false);

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(180, 180, 180, 0.75)'
        },
        content: {
            position: 'absolute',
            top: '100px',
            left: '200px',
            right: '200px',
            bottom: '100px',
            padding: '30px'
        },
      };

    const modal = useModal();
    const auth = useAuth();

    // This hook runs when the component loads and retrieves the profile of the selected exam.
    // The profile contains the number of subexams and their type.
    useEffect(() => {
        if (!modal.examData?.ExamId) return;
        if (!modal.historyModalOpen) return;
        setFetchStatus('no-fetch');
        trackPromise(
            fetchFunc(LINKS.XML_GET_EXAM_PROFILE, {
                method: 'POST',
                body: JSON.stringify({ examId: modal.examData.ExamId })
            })
            .then(({ msg, status, data }) => {
                setFetchStatus(status);
                setSubexamData(data.Subexams);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
                setSubexamData(undefined);
            })
        , 'history');
    }, [modal.examData?.ExamId]);

    // This hook runs after the user has selected a subexam from the dropdown list.
    // It retrieves the exam history and stores it in historyData state variable.
    useEffect(() => {
        if (!selectedSubexam) return;
        trackPromise(
            fetchFunc(LINKS.XML_GET_EXAM_HISTORY, {
                method: 'POST',
                body: JSON.stringify({ 
                    examId: selectedSubexam,
                    userId: auth.user.userId
                })
            })
            .then(({ msg, status, data }) => {
                setFetchStatus(status);
                setHistoryData(data);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
            })
        , 'history');
    }, [selectedSubexam]);

    // Clear history data, selected subexam and field when the user chooses the default value on subexam dropdown.
    useEffect(() => {
        if (selectedSubexam === '') {
            setSelectedSubexam(undefined);
            setSelectedField(undefined);
            setHistoryData(undefined);
        }
    }, [selectedSubexam]);

    // Clear state variables before closing modal.
    function clearAndClose() {
        modal.closeHistoryModal();
        setSelectedSubexam(undefined);
        setSelectedField(undefined);
        setHistoryData(undefined);
        setSubexamData(undefined);
        setFetchStatus('no-fetch');
    }

    function print(event) {

        event.preventDefault();

        // In Chromium-based browsers create an iframe to append printout elements. In Firefox browser, create a new window.
        // Save a reference to the generated object for the subsequent useEffect hook.
        let printObj, frameDocument;
        const ua = window.navigator.userAgent;
        if (ua.includes("Chrome")) {
            [ printObj, frameDocument ] = generateIframe();
            printObjRef.current = { iframe: printObj, browser: 'Chrome' };
        }
        else if (ua.includes("Firefox")) {
            [ printObj, frameDocument ] = generateWindow();
            printObjRef.current = { window: printObj, browser: 'Firefox' };
        }
        else {
            printObjRef.current = { browser: 'Not-supported' };
            alert("Το πρόγραμμα περιήγησης δεν υποστηρίζει εκτύπωση");
            return;
        }

        const bodyDiv = frameDocument.createElement("div");

        // Create an informative title.
        const title = frameDocument.createElement('h5');
        title.innerHTML = `Ιστορικό μετρήσεων για την εξέταση ${historyData.Info.ExamName}`;
        title.className = 'mb-2';
        bodyDiv.appendChild(title);

        // Add some info (patient name and print datetime)
        const info = frameDocument.createElement('p');
        const formattedDate = new Date().toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '')
        info.innerHTML = `Ονοματεπώνυμο: ${auth.info.name ?? ""}<br>Ημερομηνία εκτύπωσης: ${formattedDate}`;
        info.className = 'fs-7 mb-3';
        bodyDiv.appendChild(info);

        // Import the history table to the iframe.
        const table = frameDocument.importNode(document.querySelector('#historyTable'), true);
        bodyDiv.appendChild(table);

        // Import the chart, given that it exists.
        if (chartRef.current) {
            const imgData = chartRef.current.toBase64Image();
            const img = new Image();
            img.src = imgData;
            img.style.width = '100%';
            bodyDiv.appendChild(img);
        }

        // Append the div to the iframe and add styling.
        frameDocument.body.appendChild(bodyDiv);
        appendStyle(frameDocument);

        setReadyToPrint(true);
    }

    // This hook runs when the readyToPrint is set to true (i.e. when the print button is pushed).
    // It prints the iframe and removes it from the document.
    // Printing cannot happen in the print() function, because the toBase64Image() func delays; 
    // therefore, the chart image would appear white. 
    useEffect(() => {

        if (!readyToPrint) return;

        if (printObjRef.current.browser === 'Chrome') {
            const iframe = printObjRef.current.iframe;
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        }
        else if (printObjRef.current.browser === 'Firefox') {
            const window = printObjRef.current.window;
            window.document.close()
            window.focus();
            window.print();
            window.close();
        };

        setReadyToPrint(false);

    }, [readyToPrint]);

    const fieldCount = useMemo(() => historyData?.Fields.length, [historyData?.Fields]);
    
    return (
        <ReactModal isOpen={modal.historyModalOpen} onRequestClose={clearAndClose} style={customStyles} ariaHideApp={false} >
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={{ position: 'fixed', top: '100px', left: '200px' }} onClick={clearAndClose}></button>
            <div className="row d-flex justify-content-between mb-3">
                <div className="col">
                    <HistoryTable historyData={historyData?.History[selectedField]} fieldCount={fieldCount} />
                </div>
                <LoadingIndicatorSm area='history' />
                <div className="col">
                    <div className="d-flex justify-content-end align-items-start">
                        {selectedField && 
                            <button className="btn btn-primary me-3">
                                <i className="bi bi-printer-fill" onClick={e => print(e)} />
                            </button>
                        }
                        <div>
                            <SubexamSelect subexamData={subexamData} setGlobalExam={setSelectedSubexam} />
                            <FieldSelect fieldArr={historyData?.Fields} setGlobalField={setSelectedField} />
                        </div>
                    </div>
                </div>
            </div>
            <HistoryAlert status={fetchStatus} />
            <HistoryChart historyData={historyData?.History[selectedField]} chartRef={chartRef} />
        </ReactModal>
    );
}
