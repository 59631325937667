import React from 'react';
import { isMobile } from 'react-device-detect';

export default function TokenAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'hash-valid':
            return (
                <React.Fragment>
                    <p className={isMobile ? "fs-7" : "fs-6"}>
                        O <b>Ιατρικός Φάκελος</b> είναι μια νέα υπηρεσία που προσφέρεται από τα διαγνωστικά κέντρα "Παπανδρέου Ιατρικές Υπηρεσίες". Μέσω της υπηρεσίας αυτής μπορείτε να αποκτήσετε με ασφαλή τρόπο πρόσβαση στο ιστορικό όλων των εξετάσεων που έχετε πραγματοποιήσει στα διαγνωστικά μας κέντρα.
                    </p>
                    <p className={isMobile ? "fs-7" : "fs-6"}>
                        Προκειμένου να εγγραφείτε, αρκεί να συμπληρώσετε την διεύθυνση e-mail που έχετε δηλώσει στο διαγνωστικό κέντρο και να πατήσετε "Υποβολή". Για περισσότερες πληροφορίες μπορείτε να επικοινωνήσετε με το τηλεφωνικό μας κέντρο.
                    </p>
                </React.Fragment>
            );
        case 'hash-invalid':
            return (
                <div className="alert alert-danger">
                    Ο σύνδεσμος έχει λήξει. Εάν έχετε ήδη υποβάλλει την φόρμα, θα λάβετε ένα e-mail με περισσότερες οδηγίες. Παρακάλω ελέγξτε και στον φάκελο ανεπιθύμητης αλληλογραφίας (spam).
                </div>
            );
        case 'already-registered':
            return (
                <div className="alert alert-warning">
                    Ο λογαριασμός έχει ήδη δημιουργηθεί και έχετε λάβει ένα email με περισσότερες οδηγίες. Παρακάλω ελέγξτε και στον φάκελο ανεπιθύμητης αλληλογραφίας (spam). <br />
                    Αν έχετε ενεργοποιήσει τον λογαριασμό σας, συνδεθείτε στο my.medpap.gr πατώντας <a href="/auth/login">εδώ</a>.
                </div>
            );
        case 'max-tries-exceeded':
            return (
                <div className="alert alert-danger">
                    Ο σύνδεσμος δεν είναι πλέον έγκυρος, γιατί έγιναν πολλαπλές αποτυχημένες προσπάθειες. Η εγγραφή σας μπορεί να ολοκληρωθεί μόνο με επίσκεψη στο διαγνωστικό κέντρο για ταυτοποίηση στοιχείων.
                </div>
            );
        case 'conflict-email':
            return (
                <div className="alert alert-danger">
                    Ο σύνδεσμος δεν είναι πλέον έγκυρος, γιατί η διεύθυνση email χρησιμοποιείται από άλλον χρήστη. Η εγγραφή σας μπορεί να ολοκληρωθεί μόνο με επίσκεψη στο διαγνωστικό κέντρο για ταυτοποίηση στοιχείων.
                </div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}
