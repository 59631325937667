import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';

export default function LoginMechanism() {
    const auth = useAuth();
    const location = useLocation();

    const from = location.state?.from?.pathname ?? ROUTES.DEFAULT;
    const fromState = location.state?.savedState ?? {};
    const redirected = location.state?.redirected ?? false;

    if (auth.user) {
        return <Navigate to={from} state={fromState} replace={true} />
    }

    return <LoginForm redirected={redirected} />
}
