import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { useJsonFetch } from 'hooks/useFetch';

function ContactModal({ isOpen, setIsOpen }) {

    let customStyles = {}, closeBtnStyle = {};
    if (isMobile) {
        customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(180, 180, 180, 0.75)'
            },
            content: {
                position: 'absolute',
                top: '25%',
                left: '5%',
                right: '5%',
                bottom: '28%',
                padding: '30px'
            }
        };
        closeBtnStyle = { position: 'fixed', top: '25%', left: '5%' };
    }
    else {
        customStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(180, 180, 180, 0.75)'
            },
            content: {
                position: 'absolute',
                top: '40%',
                left: '20%',
                right: '20%',
                bottom: '33%',
                padding: '30px'
            }
        };
        closeBtnStyle = { position: 'fixed', top: '40%', left: '20%' };
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <ReactModal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={closeBtnStyle} onClick={closeModal}></button>
            <p>Μπορείτε να επικοινωνήσετε με το διαγνωστικό κέντρο σχετικά με δυσλειτουργία του Diagnosis Portal στέλνοντας email στην διεύθυνση <b>portal-admin@medpap.gr</b> ή τηλεφωνώντας στο νούμερο 2105222500 κατά τις εργάσιμες ημέρες και ώρες.</p>
            <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={closeModal}>Κλείσιμο</button>
            </div>
        </ReactModal>
    )
}

export default function Navbar() {

    const auth = useAuth();
    const fetchFunc = useJsonFetch();
    const dropdownStyle = { textDecoration: 'none', color: 'black' };

    const [ contactModalOpen, setContactModalOpen ] = useState(false);

    // This hook runs after the user logs in and queries the API for user info.
    // User info is stored in an object under auth.
    useEffect(() => {
        if (!auth.user) return;
        fetchFunc(LINKS.USER_INFO, {
            method: 'GET'
        })
        .then(({ info }) => {
            auth.setInfo({
                name: `${info.FirstName ?? ""} ${info.LastName ?? ""}`,
                fatherName: info.FatherName,
                ssn: info.Amka,
                birthdate: info.BirthDate,
                age: info.Age,
                gender: info.Sex
            });
        })
        .catch(e => console.error(e));
    }, [auth.user?.id])

    function Brand() {
        if (isMobile) return (
            <span className="navbar-brand ms-1 me-3" style={{ fontSize: '16px' }}>Ιατρικός Φάκελος</span>
        );
        else return (
            <span className="navbar-brand mx-3">Ιατρικός Φάκελος</span>
        )
    }

    // The right part of the navigation bar is empty before login.
    // After login, it returns a dropdown menu which displays the user's name and email (desktop) or just an icon (mobile). 
    // In both versions, the menu has 4 links: delegations, contact, manual and logout.
    function RightPart() {

        const dropdownToggle = useMemo(() => {
            if (isMobile) return <i className="bi bi-person-fill"></i>
            else return (
                <span className='me-1'>
                    <i className="bi bi-person-fill me-2"></i>
                    {auth.info?.name ? 
                        `${auth.info?.name} (${auth.user?.email ?? ""})` :
                        `${auth.user?.email}`
                    }
                </span>
            );
        }, [isMobile]);

        if (!auth.user) return <></>;
        return (
            <DropdownButton bsPrefix='btn btn-primary p-0' title={dropdownToggle}>
                { auth.user.accessLevel === 'user' &&
                    <Dropdown.ItemText>
                        <Link to={ROUTES.DELEGATE_LIST} style={dropdownStyle} >
                            <span>
                                <i className="bi bi-person-check-fill me-2"></i>
                                Παραχώρηση πρόσβασης
                            </span>
                        </Link>
                    </Dropdown.ItemText>
                }
                <Dropdown.ItemText>
                    <a href={isMobile ? LINKS.MANUAL_MOBILE : LINKS.MANUAL_WEB} target='_blank' rel='noreferrer' style={dropdownStyle}>
                        <span>
                            <i className="bi bi-question-circle-fill me-2"></i>
                            Εγχειρίδιο χρήσης
                        </span>
                    </a>
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                    <Link to="#" onClick={() => setContactModalOpen(true)} style={dropdownStyle}>
                        <span>
                            <i className="bi bi-telephone-fill me-2"></i>
                            Επικοινωνία
                        </span>
                    </Link>
                </Dropdown.ItemText>
                <Dropdown.ItemText>
                    <Link to={ROUTES.AUTH_LOGOUT} style={dropdownStyle} >
                        <span>
                            <i className="bi bi-box-arrow-right me-2"></i>
                            Αποσύνδεση
                        </span>
                    </Link>
                </Dropdown.ItemText>
            </DropdownButton>
        );
    }

    // The left part of the navigation bar has a link to the home page.
    // The mobile version is more compact than the web version.
    function LeftPart() {
        if (!auth.user) return <></>;
        if (isMobile) return (
            <ul className="navbar-nav me-auto mb-0">
                <li className="nav-item">
                    <Link className="nav-link" to={ROUTES.DEFAULT}>
                        <i className="bi bi-house-fill"></i>
                    </Link>
                </li>
            </ul>
        )
        else return(
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className="nav-link" to={ROUTES.DEFAULT}>
                        <div className="d-flex">
                            <i className="bi bi-house-fill me-1"></i>
                            <span>Αρχική σελίδα</span>
                        </div>
                    </Link>
                </li>
            </ul>
        )
    }
    
    function NavbarContent() {
        return (
            <React.Fragment>
                <Brand />
                <div className="collapse navbar-collapse">
                    <LeftPart />
                    <RightPart />
                </div>
                <ContactModal isOpen={contactModalOpen} setIsOpen={setContactModalOpen} />
            </React.Fragment>
        )
    }

    const container = document.getElementById("navbar-content-root");
    return ReactDOM.createPortal(<NavbarContent />, container);
}