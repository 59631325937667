import { Buffer } from 'buffer';
import { ROUTES } from "data/routes";
import { getJWTinfo, validJWT } from "utils/jwt";
import React, { useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

/**
 * @typedef {{ userName: string, accessType: string }} UserData
 * @typedef {{ user: UserData, signin: (accessToken: string, refreshToken: string) => void, signout: () => void }} AuthManager
 */

const AuthContext = React.createContext(null);


function AuthProvider({ children }) {
    
    // This function retrieves the refresh token from localStorage and extracts user data from it.
    // It returns an object with user id and description, or null if there is no refresh token.
    function getUserDataFromJWT() {
        try {
            let userObj = null;
            const token = localStorage.getItem("refresh_token");
            if (validJWT(token, 'refresh')) {
                const payload = getJWTinfo(token);
                userObj = {
                    id: payload.sub,
                    email: payload.email,
                    accessLevel: payload.level
                }
            }
            return userObj;
        }
        catch(e) {
            console.error(e);
            return null;
        }
    }
    
    const [ info, setInfo ] = useState(undefined); // Holds miscalleneous user info (e.g. first and last name, gender, birthday)
    const [ user, setUser ] = useState(getUserDataFromJWT); // Holds authorization data (user id, email (instead of username), access level)
    
    /**
     * @param {string} accessToken 
     * @param {string} refreshToken 
     */
    function signin(accessToken, refreshToken) {

        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken);

        const payloadString = Buffer.from(refreshToken.split('.')[1], 'base64').toString();
        const { sub, email, level } = JSON.parse(payloadString);
        setUser({
            id: sub,
            email: email,
            accessLevel: level
        });
    }

    function signout() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        sessionStorage.clear();
        setUser(null);
        setInfo(null);
    }

    const retValue = { user, info, signin, signout, setInfo };
    return <AuthContext.Provider value={retValue}>{children}</AuthContext.Provider>
}

/**
 * @returns {AuthManager}
 */
function useAuth() {
    return useContext(AuthContext);
}

// withSecurity restricts access to pages in two ways:
// 1) Users muyst have logged in successfully.
// 2) There is access level on each token. A token can access pages with an access level equal or lower to its own, according to accessLevelArr. 
function withSecurity(WrappedComponent, accessLevel='delegate') {
    const accessLevelArr = ['delegate', 'user'];
    const WithSecurityComponent = function (props) {
        const auth = useAuth();
        const location = useLocation();

        if (auth.user) {
            const requiredLevel = accessLevelArr.indexOf(accessLevel);
            const tokenLevel = accessLevelArr.indexOf(auth.user.accessLevel);
            if (tokenLevel >= requiredLevel) return <WrappedComponent {...props} />;
            else return <Navigate to={ROUTES.DEFAULT} replace={true} />
        } else {
            return <Navigate to={ROUTES.AUTH_LOGIN} state={{ from: location, redirected: true }} replace={true} />
        }
    }
    WithSecurityComponent.displayName = `withSecurity(${WrappedComponent.displayName ?? WrappedComponent.name ?? "WrappedComponent"})`;
    return WithSecurityComponent;
}

export { AuthProvider, useAuth, withSecurity }