import React, { useState, useEffect, useRef } from 'react';

import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    Filler
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    Filler
)

const colors = { 
    black: 'rgb(0, 0, 0)',
    darkBlue: 'rgba(54, 162, 235, 1.0)', lightBlue: 'rgba(54, 162, 235, 0.5)',
    darkGrey: 'rgba(201, 203, 207, 1.0)', lightGrey: 'rgba(201, 203, 207, 0.5)'
};

export default function HistoryChart({ historyData, chartRef }) {

    // const chartRef = useRef(undefined);
    const [ chartData, setChartData ] = useState(undefined);
    const [ chartOptions, setChartOptions ] = useState(undefined);

    // This hook runs every time the incoming data changes and sets the chart data and options.
    // The incoming data are like: [{ Date, ResultValue, IsAbnormal, Units, NormalValues: { UpperLimit, LowerLimit, Text }, Precision }, ...].
    // If there are no data available, or there is less than 2 result instances, it cleans up local variables and returns.
    // In case the normal values change slightly (i.e. less than 30%), display all measurements and normal values.
    // But if the change is more dramatic, omit old measurements and normal values from the chart.
    useEffect(() => {

        if (!historyData) { setChartData(undefined); setChartOptions(undefined); return; };

        let labelData = [], numericData = [], upperLimitArr = [], lowerLimitArr = [];
        let precision, prevUpperNormal, currUpperNormal, prevLowerNormal, currLowerNormal, prevNormalValuesRange, currNormalValuesRange;

        // This loop goes through all result objects and pushes the results, dates, upper and lower normal values
        // the equivalent arrays. It also computes the normal values range and compares to the range of the previous loop.
        // If the percent change is greater that 30%, it empties data arrays. 
        historyData.forEach(resObj => {
            
            currUpperNormal = resObj.NormalValues.UpperLimit && parseFloat(resObj.NormalValues.UpperLimit.replace(',', '.'));
            currLowerNormal = resObj.NormalValues.LowerLimit && parseFloat(resObj.NormalValues.LowerLimit.replace(',', '.'));
            currNormalValuesRange = (currUpperNormal && currLowerNormal) && currUpperNormal - currLowerNormal; 
            
            if (Math.abs((currNormalValuesRange - prevNormalValuesRange) / prevNormalValuesRange) > 0.3) {
                labelData = [];
                numericData = [];
                upperLimitArr = [];
                lowerLimitArr = [];
            }

            labelData.push(resObj.Date ?? "");
            upperLimitArr.push(currUpperNormal);
            lowerLimitArr.push(currLowerNormal);
            numericData.push(parseFloat(resObj.ResultValue.replace(',', '.')));
            
            prevUpperNormal = currUpperNormal;
            prevLowerNormal = currLowerNormal;
            prevNormalValuesRange = currNormalValuesRange;
            
        });

        const resultCount = labelData.length;
        const upperNormal = currUpperNormal, lowerNormal = currLowerNormal;
        if (resultCount < 2) { setChartData(undefined); setChartOptions(undefined); return; };

        setChartData({
            labels: labelData,
            datasets: [
                {
                    label: 'Μέτρηση',
                    data: numericData,
                    pointStyle: 'circle',
                    pointRadius: 7,
                    pointHoverRadius: 10,
                    borderColor: colors.darkBlue,
                    backgroundColor: colors.lightBlue
                },
                {
                    label: 'Ανώτατη φυσιολογική τιμή',
                    data: upperLimitArr,
                    pointStyle: false,
                    borderColor: colors.darkGrey,
                    backgroundColor: colors.lightGrey,
                    stepped: 'middle',
                    fill: (lowerNormal) ? '+1' : 'start' // +1 -> Fill to next dataset, start -> fill to the begining of y-axis

                },
                {
                    label: 'Κατώτατη φυσιολογική τιμή',
                    data: lowerLimitArr,
                    pointStyle: false,
                    borderColor: colors.darkGrey,
                    stepped: 'middle',
                    fill: (upperNormal) ? '-1' : 'end' // -1 -> Fill to previous dataset, end -> fill to the end of y-axis
                }
            ]
        });

        setChartOptions({
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: ['Ιστορικότητα μετρήσεων'],
                    color: colors.black,
                    font: {
                        size: 20,
                        weight: 'normal'
                    }
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        generateLabels: () => {
                            // Generate a label from results line and one for normal values filled area.
                            // If there are no normal values, omit legend altogether.
                            if (upperNormal || lowerNormal) return ([
                                { text: 'Μετρήσεις', datasetIndex: 0, fillStyle: colors.lightBlue, strokeStyle: colors.darkBlue, fontColor: colors.black }, 
                                { text: 'Φυσιολογικές τιμές', datasetIndex: 1, fillStyle: colors.lightGrey, strokeStyle: colors.darkGrey, fontColor: colors.black }
                            ])
                            else return [];
                        }
                    }
                },
                // Tooltip mode index displays result, upper and lower normal values on point hover.
                tooltip: {
                    mode: 'index'
                }
            },
            scales: {
                x: {
                    ticks: {
                        padding: 3, // Add space between x-axis and ticks.
                        maxRotation: 30
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    // Max y-axis value = 5% more that max{ resultsData, upperNormal (if available) }
                    // Min y-axis value = 5% less than min{ resultsData, lowerNormal (if available) }
                    suggestedMax: (upperNormal) ? Math.max(...numericData, upperNormal) * 1.05 : Math.max(...numericData) * 1.05,
                    suggestedMin: (lowerNormal) ? Math.min(...numericData, lowerNormal) * 0.95 : Math.min(...numericData) * 0.95,
                    ticks: {
                        padding: 5, // Add space between y-axis and ticks.
                        crossAlign: 'far', // Left-align ticks.
                        precision: precision
                    },
                    grid: {
                        display: false
                    }
                }
            }
        })
    }, [historyData]);

    if (!chartData || !chartOptions) return <></>;
    return (
        <Line options={chartOptions} data={chartData} height={75} width={null} ref={chartRef} />
    );
}
