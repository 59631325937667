import React from 'react';

export default function HistoryAlert({ status }) {
    switch(status) {
        case 'success':
            return <></>;
        case 'not-exists':
            return (
                <div className="alert alert-danger">Δεν βρέθηκαν δεδομένα για την εξέταση. Παρακαλώ προσπαθήστε αργότερα.</div>
            ); 
        case 'error':
            return (
                <div className="alert alert-danger">Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.</div>
            );
        default:
            return <></>;
    }
}
