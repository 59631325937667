import React from 'react';
import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';

export default function TokenAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'token-expired':
            return (
                <div className="alert alert-warning">
                    Ο σύνδεσμος επαναφοράς έχει λήξει. Μπορείτε να ξεκινήσετε πάλι την διαδικασία αλλαγής του κωδικού σας πατώντας <Link className="text-decoration-none" to={ROUTES.AUTH_FORGOT}>εδώ</Link>.
                </div>
            );
        case 'token-revoked':
            return (
                <div className="alert alert-warning">
                    Ο κωδικός πρόσβασης έχει αλλάξει. Μπορείτε να συνδεθείτε από την <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>σελίδα σύνδεσης</Link>.
                </div>
            );
        case 'token-valid':
            return (
                <p>Για να αλλάξετε τον κωδικό σας, συμπληρώστε την φόρμα και πατήστε "Υποβολή".</p>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Συνέβη κάποιο σφάλμα. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}