import { LINKS } from "data/links";
import { useAuth } from "hooks/useAuth";
import { validJWT } from "utils/jwt";
import { useCallback } from "react";

// A custom error to distinguish unauthorized errors.
class FetchUnauthorizedError extends Error {
    constructor(message) {
        super(message);
        this.name = 'Fetch Unauthorized Error';
        this.status = 401;
    }
}

// This function retrieves an access token from the localStorage and checks if it is valid.
// If it has expired, it retrieves the refresh token and connects to the API to generate a new access token.
// If the refresh token has expired or does not exist, it throws a custom Unauthorized error.
async function checkAndRenewAccessToken() {

    let accessToken = localStorage.getItem('access_token');

    if (!validJWT(accessToken, 'access')) {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!validJWT(refreshToken, 'refresh')) {
            throw new FetchUnauthorizedError('Refresh token missing or expired');
        }

        const response = await fetch(LINKS.AUTH_REFRESH, {
            mode: 'cors',
            cache: 'no-store',
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });

        let payload;
        if (response.ok) {
            payload = await response.json()
        }
        else {
            throw new FetchUnauthorizedError(`Could not refresh token: ${response.statusText}`);
        }

        accessToken = payload.access_token;
        localStorage.setItem("access_token", accessToken);
    }

    return accessToken;
}

function useFetch(ufoptions = { secure: true }) {

    const auth = useAuth();
    
    // useCallback caches a function redefinition between re-renders.
    const triggerFunc = useCallback(

        async (url, options = {}) => {

            // Get a valid access token or sign out.
            let accessToken = null;
            if (ufoptions.secure) {
                try {
                    accessToken = await checkAndRenewAccessToken();
                }
                catch (e) {
                    if (e instanceof FetchUnauthorizedError) auth.signout();
                    else throw e;
                }
            }

            // Generate fetch options from ufOptions and user arguments.
            const fetchOptions = {
                mode: 'cors',
                cache: 'no-store',
                ...options,
                headers: {
                    ...(options.body ? { 'Content-Type': 'application/json' } : {}),
                    ...(ufoptions.secure ? { 'Authorization': `Bearer ${accessToken}` } : {}),
                    ...(options.headers ?? {})

                }   
            }

            if (ufoptions.secure) return (
                fetch(url, fetchOptions)
                .then(resp => {
                    if (resp.status === 401) {
                        throw new FetchUnauthorizedError();
                    }
                    return resp;
                })
                .catch(e => {
                    console.error(`Error while fetching ${url}: ${e}`);
                    if (e instanceof FetchUnauthorizedError) {
                        auth.signout();
                    }
                    else {
                        throw e;
                    }
                })
            )
            else return (
                fetch(url, fetchOptions)
                .catch(e => {
                    console.error(`Error while fetching ${url}: ${e}`);
                    throw e;
                })
            );
        }
    , [auth, ufoptions.secure]);

    return triggerFunc;
}

function useJsonFetch(ufoptions = { secure: true }) {

    const fetcherFunc = useFetch(ufoptions);
    const triggerFunc = useCallback(
        async (url, options = {}) =>
            fetcherFunc(url, options)
            .then(response => response.ok ? response.json() : null),
        [fetcherFunc]
    );
    return triggerFunc;
}

export { useFetch, useJsonFetch };