import ReactModal from 'react-modal';
import { useModal } from 'hooks/useModal';

export default function InfoModal({ refreshTree }) {

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(180, 180, 180, 0.75)'
        },
        // content: {
        //     position: 'absolute',
        //     top: '390px',
        //     left: '600px',
        //     right: '600px',
        //     bottom: '400px',
        //     padding: '30px'
        // },
        content: {
            position: 'absolute',
            top: '40%',
            left: '30%',
            right: '30%',
            bottom: '38%',
            padding: '30px'
        },
    };

    const modal = useModal();

    function closeAndRefresh() {
        refreshTree();
        modal.closeInfoModal();
        modal.setChangeDetected(false);
    }

    if (!modal?.examData) return <></>;
    else if (modal.examData.HasResult) return (
        <ReactModal isOpen={modal.infoModalOpen} onRequestClose={modal.closeInfoModal} style={customStyles} ariaHideApp={false} >
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={{ position: 'fixed', top: '40%', left: '30%' }} onClick={modal.closeInfoModal}></button>
            <p>Το αποτέλεσμα της εξέτασης έχει αλλάξει. Πατήστε το κουμπί "Ανανέωση" για να ανανεώσετε την σελίδα.</p>
            <div className="d-flex justify-content-center"><button type="button" className="btn btn-primary" onClick={closeAndRefresh}>Ανανέωση</button></div>
        </ReactModal>
    );
    else return (
        <ReactModal isOpen={modal.infoModalOpen} onRequestClose={modal.closeInfoModal} style={customStyles} ariaHideApp={false} >
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={{ position: 'fixed', top: '40%', left: '30%' }} onClick={modal.closeInfoModal}></button>
            <p>Τα αποτελέσματα για την εξέταση δεν είναι έτοιμα ακόμα. Παρακαλώ ελέγξτε πάλι αργότερα.</p>
            <div className="d-flex justify-content-center"><button type="button" className="btn btn-primary" onClick={modal.closeInfoModal}>Κλείσιμο</button></div>
        </ReactModal>
    )
}