import React from 'react';
import { useModal } from 'hooks/useModal';
import PdfButtons from 'pages/results/PdfButtons';
import DicomDropdown from 'pages/results/DicomDropdown';

export default function ExamGroupDocument({ groupData }) {

    const modal = useModal();
    const noPdfGroup = Object.values(groupData['Tests']).every(test => test['NoPdf']);
        
    if (noPdfGroup) return (
        <div className="d-flex justify-content-between align-items-center">
            <text>Το πόρισμα δεν είναι διαθέσιμο σε μορφή PDF. Eπικοινωνήσετε με το διαγνωστικό κέντρο για να λάβετε τα αποτελέσματα.</text>
            <DicomDropdown groupTestObj={groupData['Tests']} />
        </div>
    )
    else return (
        <div className="d-flex justify-content-between align-items-center">
            <text>Το πόρισμα είναι διαθέσιμο αποκλειστικά σε μορφή PDF.</text>
            <div className="d-flex justify-content-end align-items-stretch">
                <PdfButtons 
                    orderId={modal.orderId}
                    printGroupId={groupData.PrintGroupId}
                    printGroupName={groupData.PrintGroupName}
                    isWritable={true}
                />
                <DicomDropdown groupTestObj={groupData['Tests']} />
            </div>
        </div>
    )
}