import React from 'react';
import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';

export default function TokenAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'token-expired':
            return (
                <div className="alert alert-danger">
                    Ο σύνδεσμος ενεργοποίησης λογαριασμού έχει λήξει. Εάν έχετε ήδη ενεργοποιήσει τον λογαριασμό σας, δοκιμάστε να εισέλθετε από 
                    την <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>σελίδα σύνδεσης</Link>. Εάν δεν ενεργοποιήσατε τον λογαριασμό σας θα πρέπει να επικοινωνήσετε εκ νέου με το διαγνωστικό κέντρο.
                </div>
            );
        case 'token-revoked':
            return (
                <div className="alert alert-warning">
                    Ο λογαρισμός έχει ήδη ενεργοποιηθεί. Μπορείτε να συνδεθείτε από την <Link to={ROUTES.AUTH_LOGIN}>σελίδα σύνδεσης</Link>.
                </div>
            );
        case 'token-valid':
            return (
                <p>Για να ενεργοποιήσετε τον λογαριασμό σας, συμπληρώστε την φόρμα και πατήστε "Υποβολή".</p>
            );
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        default:
            return <></>;
    }
}
