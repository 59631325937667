import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { useAuth } from 'hooks/useAuth';
import { useTitle } from 'hooks/useTitle';
import { useJsonFetch } from 'hooks/useFetch';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import LoginAlert from 'pages/login/LoginAlert';
import LoadingIndicator from 'components/LoadingIndicator';

export default function LoginForm({ redirected }) {

    useTitle("Σύνδεση");
    const fetchFunc = useJsonFetch({ secure: false });
    const auth = useAuth();

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    // Honeypot variables
    const startTime = useRef(performance.now());
    const [ field1, setField1 ] = useState('');
    const [ field2, setField2 ] = useState('');

    // Timer data
    let timer;
    const [ count, setCount ] = useState(0);
    const [ enableSubmit, setEnableSubmit ] = useState(false);

    // This hook adds a delay before enabling the submit button. 
    // enableSubmit variable acts as a flag indicating that submit button should be activated.
    // The submit button is enabled after 5 * 100 = 500 ms.
    useEffect(() => {
        if (!enableSubmit) return;
        timer = !timer && setInterval(() => {
            setCount(prevCount => prevCount + 1)
        }, 100);
        if (count === 5) {
            setCount(0);
            clearInterval(timer);
            setEnableSubmit(false);
            setSubmitDisabled(false);
        }
        return () => clearInterval(timer);
    }, [enableSubmit, count]);

    // Login handler invokes a request to the the backend. Apart from the email and password,
    // honeypot variables and time spent count are included in the request body, so that backend can distinguish spam requests.
    function loginHandler(e) {
        e.preventDefault();
        setSubmitDisabled(true); // Disable submit button to prevent multiple requests
        setFetchStatus('no-fetch'); // Clear any error messages
        trackPromise(
            fetchFunc(LINKS.AUTH_LOGIN, {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    password: password,
                    honeypot: { value1: field1, value2: field2 },
                    timeSpent: parseFloat(((performance.now() - startTime.current) / 1000).toFixed(1))
                })
            })
            .then(({ msg, status, access_token, refresh_token }) => {
                setFetchStatus(status);
                if (status === 'auth-success') {
                    auth.signin(access_token, refresh_token);
                }
                else setEnableSubmit(true); // Enable submit on error after a short delay
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
                setEnableSubmit(true);
            })
        , 'login');
    }

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <div className="row">
                    <h4 className='mb-4'>Σύνδεση χρήστη</h4>
                    { redirected && <div className='alert alert-warning'>Έχετε αποσυνδεθεί. Για να χρησιμοποιήσετε το Diagnosis Portal θα πρέπει να συνδεθείτε.</div> }
                </div>
                <div className="row">
                    <div className="col-12 border border-2 rounded-2 p-4">
                        <form className="row g-3" onSubmit={loginHandler}>
                            <div className="col-12">
                                <label htmlFor="email285" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email285" value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="pwd937" className="form-label">Κωδικός πρόσβασης</label>
                                <input type="password" className="form-control" id="pwd937" value={password} onChange={e => setPassword(e.target.value)} required />
                            </div>
                            <label htmlFor="pp147" className="honeyfield"></label>
                            <input type="text" className="honeyfield" autoComplete="new-password" tabIndex="-1" id="pp147" value={field1} onChange={e => setField1(e.target.value)} />
                            <label htmlFor="pp150" className="honeyfield"></label>
                            <input type="text" className="honeyfield" autoComplete="new-password" tabIndex="-1" id="pp150" value={field2} onChange={e => setField2(e.target.value)} />
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <Link className="text-decoration-none" to={ROUTES.AUTH_FORGOT}>Ξέχασα τον κωδικό πρόσβασης</Link>
                                <button type="submit" className="btn btn-primary" disabled={submitDisabled}>Σύνδεση</button>
                            </div>
                        </form>
                    </div>
                </div>
                <LoadingIndicator area="login" />
                <div className="row mt-4">
                    <LoginAlert status={fetchStatus} />
                </div>
            </div>
        </div>
    )
}

