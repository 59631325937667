import React from 'react';

export default function ExamGroupTableRow(props) {

    const data = {
        Column1: "",
        Column2: "",
        Column3: "",
        Column1Classes: "",
        Column2Classes: "",
        Column3Classes: ""
    };

    // Data fields regex
    const regexData = /@([RrLlHh]?)\d*(?:\d+\.\d+)?(?:{[\s\S\r]*?})?<(.*?)>/g;
    // Formatting regex WITHOUT BOLD
    const regexFormatting = /![LlRrTtOoIiUu]+/g;
    // Formatting regex WITH BOLD
    const regexBold = /![LlRrTtOoBbIiUu]+/g;
    // Exam descr regex
    const regexDescription = /#(?:[LlRrTtOoBbIiUu]+)?\d*(?:\d+\.\d+)?<ex_descr>/g;
    // Exam normal values regex
    const regexNotes = /#(?:[LlRrTtOoBbIiUu]+)?\d*(?:\d+\.\d+)?<ex_normalnotes>/g;
    // Rubbish fields regex
    const regexRubbish = /[@#][LlRrHhTtOoBbIiUu]?\d*(?:\.\d+)?(?:{[\s\S\r]*?})?/g;

    // Replace rules with values
    for (let col of ['Column1', 'Column2', 'Column3']) {
        let colString = props.examLayoutObj[col];
        let colClasses = new Set();

        if (colString == null) {
            continue;
        }
        
        colString = colString.replaceAll(regexFormatting, '');
        colString = colString.replaceAll(regexDescription, props.examVariousData['FullName']);
        colString = colString.replaceAll(regexNotes, props.examVariousData['NormalValues'] ?? "");
        for (let matchArr of colString.matchAll(regexData)) {
            if (matchArr[1].toLowerCase() === 'h') {
                colString = colString.replace(matchArr[0], "")
            }
            else if (props.examFields[matchArr[2]]?.abnormal) {
                colString = colString.replace(matchArr[0], props.examFields[matchArr[2]]?.value ?? "")
                colClasses.add("bold");
                colClasses.add("abnormal");
            } else {
                colString = colString.replace(matchArr[0], props.examFields[matchArr[2]]?.value ?? "")
            }
        }
        colString = colString.replaceAll(regexRubbish, '');
        if (regexBold.test(colString)) {
            colString = colString.replaceAll(regexBold, "");
            colClasses.add("bold");
        }
        data[col] = colString.trimEnd();
        data[`${col}Classes`] = Array.from(colClasses).join(' ');
    }
    
    return (
        <tr className='exam-layout-row'>
            <td className={data.Column1Classes}>{data.Column1}</td>
            <td className={data.Column2Classes}>{data.Column2}</td>
            <td className={data.Column3Classes}>{data.Column3}</td>
        </tr>
    );
}