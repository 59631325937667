import React, { useRef } from 'react';
import ExamGroupSwitch from 'pages/results/ExamGroupSwitch';

export default function ExamGroupAccordion(props) {

    const btnRef = useRef();
    const divRef = useRef();

    function toggleCollapse() {
        btnRef.current.classList.toggle("collapsed");
        divRef.current.classList.toggle("show");
    }
    
    const titleId = "title_" + props.id;
    return (
        <div className="accordion-item">
            <button className="accordion-button collapsed" ref={btnRef} onClick={toggleCollapse}>
                <ul className="order-group-titles" id={titleId}>
                    <li className="order-group-titles-title">{props.groupName}</li>
                    <li className="order-group-titles-subtitle">{props.groupLayout['GroupSubtitle']}</li>
                </ul>
            </button>
            <div className="accordion-collapse collapse container-exam-data p-3" ref={divRef}>
                <ExamGroupSwitch {...props} />
            </div>
        </div>
    )
}