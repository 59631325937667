import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { LINKS } from 'data/links';
import { useAuth } from 'hooks/useAuth';
import { useJsonFetch } from 'hooks/useFetch';
import { LoadingIndicatorInline } from 'components/LoadingIndicator';
import { isBrowser, isMobile } from 'react-device-detect';
import { useToast } from 'hooks/useToast';

export default function DelegateDicom({ orderId }) {

    const auth = useAuth();
    const toast = useToast();
    const fetchFunc = useJsonFetch();
    const [ urlList, setUrlList ] = useState([]);
    const [ btnDisabled, setBtnDisabled ] = useState(false);
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');

    function getDicomInfo(e) {
        e.preventDefault();
        setBtnDisabled(true);
        trackPromise(
            fetchFunc(LINKS.GET_RIS_INFO, {
                method: 'POST',
                body: JSON.stringify({ order_id: orderId })
            })
            .then(({ status, url }) => {
                setFetchStatus(status);
                if (status === 'success') setUrlList(url);
            })
            .catch(e => {
                console.error(e);
                toast.addToast('Μη διαθέσιμο RIS', 'Η υπηρεσία RIS δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.');
                setFetchStatus('no-fetch');
            })
        , `ris-${orderId}`)
    }

    if (auth.user.accessLevel !== 'delegate') return <></>;

    if (fetchStatus === 'no-fetch') 
        return (
            <button className={`btn btn-primary btn-sm py-0 px-1 ${isMobile ? 'mt-1' : 'ms-2'}`} style={{ fontSize: isMobile ? '9px' : '13px' }} onClick={getDicomInfo} disabled={btnDisabled}>
                RIS Info
                <LoadingIndicatorInline area={`ris-${orderId}`} />
            </button>
        );
    else if (fetchStatus === 'not-found')
        return (
            <button className={`btn btn-primary btn-sm py-0 px-1 ${isMobile ? 'mt-1' : 'ms-2'}`} style={{ fontSize: isMobile ? '9px' : '13px' }} disabled>
                No RIS Info
            </button>
        )
    else if (fetchStatus === 'success') {
        if (urlList.length === 1) {
            if (isMobile) return (
                <a className="btn btn-primary btn-sm mt-1 py-0 px-1" style={{ fontSize: '9px' }} href={urlList[0]?.url} target="_blank" rel="noopener noreferrer">
                    Study
                </a>
            );
            else return (
                <OverlayTrigger
                    key='ris-overlay'
                    placement='top'
                    className="p-0 m-0"
                    overlay={
                        <Tooltip id='ris-tooltip'>
                            Η υπηρεσία αυτή διατίθεται αποκλειστικά σε ιατρούς και απαιτεί ειδικό όνομα χρήστη και συνθηματικό. Επικοινωνήστε με το διαγνωστικό κέντρο.
                        </Tooltip>
                    }
                >
                    <a className="btn btn-primary btn-sm ms-2 py-0 px-1" style={{ fontSize: '13px' }} href={urlList[0]?.url} target="_blank" rel="noopener noreferrer">
                        Study
                    </a>
                </OverlayTrigger>
            );
        }
        else {
            if (isMobile) return (
                <Dropdown className="p-0">
                    <Dropdown.Toggle variant="primary" size="sm" className="py-0" style={{ fontSize: '9px' }}>Studies</Dropdown.Toggle>
                    <Dropdown.Menu>
                        { urlList.map((elem, index) =>
                            <Dropdown.Item href={elem?.url} key={index} target="_blank" rel="noopener noreferrer" className="text-primary">DICOM study {index+1}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            );
            else return (
                <OverlayTrigger
                    key='ris-overlay'
                    placement='top'
                    className="p-0 m-0"
                    overlay={
                        <Tooltip id='ris-tooltip'>
                            Η υπηρεσία αυτή διατίθεται αποκλειστικά σε ιατρούς και απαιτεί ειδικό όνομα χρήστη και συνθηματικό. Επικοινωνήστε με το διαγνωστικό κέντρο.
                        </Tooltip>
                    }
                >
                    <Dropdown className="p-0 ms-2 d-flex align-items-stretch">
                        <Dropdown.Toggle variant="primary" size="sm" className="py-0" style={{ fontSize: '13px' }}>Studies</Dropdown.Toggle>
                        <Dropdown.Menu>
                            { urlList.map((elem, index) =>
                                <Dropdown.Item href={elem?.url} key={index} target="_blank" rel="noopener noreferrer" className="text-primary">DICOM study {index+1}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </OverlayTrigger>
            );
        }
    }
    else return <></>;
}
