import { useToggle, useToggleRef } from "hooks/useToggle";
import { addPropsToChildren } from "utils/addProps";

export function OrdersList(props) {
    const ref = useToggleRef(props.show);

    return (
        <ul className="orders-list collapse fade" ref={ref}>
            {props.children}
        </ul>
    )
}

export function OrdersListItem(props) {
    const [_, toggle, setToggle] = useToggle(false);

    return (
        <li className={`orders-list-item my-2 pe-0 ${toggle || "border rounded"}`}>
            <h5 className={`mb-0 ${toggle && "text-primary"}`} style={{ cursor: "pointer" }} onClick={() => setToggle()}>
                <i className={`bi bi-caret-${ toggle ? "down" : "right" }`}></i>
                <span className="ms-2">{props.text}</span>
            </h5>
            {
                addPropsToChildren(props.children, { show: toggle })
            }
        </li>
    )
}