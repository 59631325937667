import React, { useState, useEffect, useMemo } from 'react';

export default function FieldSelect({ fieldArr, setGlobalField }) {

    // The local selectedField variable helps avoid updating modal component while this is rendering.
    const [selectedField, setSelectedField] = useState(undefined);

    // This hook stores an array with the fields that have ResultType 1.
    // It also clears the local selected field when the field array changes.
    const numericFields = useMemo(() => {
        setSelectedField(undefined); 
        if (!fieldArr) return [];
        return fieldArr.filter(field => field.ResultType === '1');
    }, [fieldArr]);

    // This hook updates the global selected field using the local field variable.
    // If there is only one field available, the global variable is updated automatically.
    useEffect(() => {
        if (numericFields?.length === 1) {
            setGlobalField(numericFields[0].FieldName);
            return;
        }
        setGlobalField(selectedField); 
    }, [selectedField, numericFields?.length]);

    // This component returns a dropdown select only if there are at least two fields with numeric values.
    if (!fieldArr || !numericFields) return <></>;
    if (numericFields?.length < 2) return <></>;
    return (
        <select className="form-select mt-2" value={selectedField} onChange={e => setSelectedField(e.target.value)}>
            <option value="">Επιλέξτε πεδίο</option>
            {numericFields.map((fieldObj, i) => (
                <option key={`field-${i}`} value={fieldObj.FieldName}>{fieldObj.FieldName}</option>
            ))}
        </select>
    );
}
