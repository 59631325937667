import React from 'react';

export default function ProgressBar({ width }) {
    if (width === 0) return <></>;
    return (
        <div className="progress mt-1 w-100" style={{height: "8px"}}>
            <div className="progress-bar bg-success" role="progressbar" style={{width: `${width}%`}} />
        </div>  
    );
}
