import React from 'react';
import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';

export default function RecoverAlert({ status }) {
    switch (status) {
        case 'no-fetch':
            return <></>;
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'not-exists':
            return (
                <div className="alert alert-danger">
                    Συνέβη κάποιο σφάλμα κατά την αλλαγή του κωδικού πρόσβασης. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'not-activated':
            return (
                <div className="alert alert-danger">
                    Ο λογαρισμός δεν έχει ενεργοποιηθεί. Παρακαλώ ακολουθήστε τις οδηγίες στο email ενεργοποίησης.
                </div>
            );
        case 'token-error':
            return (
                <div className="alert alert-danger">
                    Συνέβη κάποιο σφάλμα κατά την αλλαγή του κωδικού πρόσβασης. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            )
        case 'phone-error':
            return (
                <div className="alert alert-danger">
                    Το κινητό τηλέφωνο δεν ταιριάζει με το δηλωθέν κινητό. Παρακαλώ συμπληρώστε το σωστό νούμερο.
                </div>
            )
        case 'success':
            return (
                <div className="alert alert-success">
                    Ο κωδικός πρόσβασης άλλαξε επιτυχώς. Πατήστε <Link className="text-decoration-none" to={ROUTES.AUTH_LOGIN}>εδώ</Link> για να συνδεθείτε.
                </div>
            );
        default:
            return <></>;
    }
}
