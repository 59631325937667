import { LINKS } from "data/links";
import React, { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";

import { useJsonFetch } from "hooks/useFetch";
import { ModalProvider } from "hooks/useModal";
import { ToastProvider } from "hooks/useToast";

import PdfToast from "pages/ordersTree/PdfToast";
import OrdersAlert from "pages/ordersTree/OrdersAlert";
import OrdersFilter from "pages/ordersTree/OrdersFilter";
import OrdersDateTree from "pages/ordersTree/OrdersDateTree";
import OrdersTreeRefresher from "pages/ordersTree/OrdersTreeRefresher";
import UnsettledModal from "pages/ordersTree/UnsettledModal";

import HistoryModal from "pages/history/HistoryModal";
import ResultsModal from "pages/results/ResultsModal";
import InfoModal from "pages/history/InfoModal";

import { timeStr } from "utils/time";
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator, { LoadingIndicatorSm } from "components/LoadingIndicator";

import { isMobile } from "react-device-detect";

export default function Orders() {
    // Holds the original data.
    const [ data, setData ] = useState([]);
    // Holds the filters that the user has selected. Will be used to filter the original data
    // during the creation of the tree that will be fed to the view component.
    const [ activeFilters, setActiveFilters ] = useState([]);

    const fetchFunc = useJsonFetch({ secure: true });
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');

    // STEP 1: Retrieve the data in Array of Object format.
    useEffect(() => {
        trackPromise(
            fetchFunc(LINKS.XML_GET_VISITS, {
                method: 'GET'
            })
            .then(({ status, visit_data }) => {
                setFetchStatus(status);
                if (status === 'success' || status === 'no-visits') {
                    setData(visit_data);
                }
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
            })
        , 'tree');
    }, []);

    // STEP 2: Extract the filter names.
    const filterNames = useMemo(() => {
        const f = new Set();

        data.forEach(item => item.tags.forEach(t => f.add(t.ExamDescr)));

        return Array.from(f);
    }, [data]);

    // STEP 3: The newly extracted filter names will replace all previous active filters.
    useEffect(() => {
        setActiveFilters(filterNames);
    }, [filterNames]);

    // STEP 4: Construct the tree. Items will be filtered out based on the active filters
    // Everytime the user changes a filter selection, the tree has to be re-evaluated.
    const tree = useMemo(() => {
        const treeData = {};

        data.forEach(item => {

            if (!item.tags.some(t => activeFilters.includes(t.ExamDescr))) return;

            if (!treeData.hasOwnProperty(item.year)) treeData[item.year] = {};

            if (!treeData[item.year].hasOwnProperty(item.month)) treeData[item.year][item.month] = [];

            treeData[item.year][item.month].push({
                date: item.date,
                visit: item.visit,
                tags: item.tags
            });
        });

        return treeData;
    }, [activeFilters]);

    // A callback function to update the selected filters. Second variable is used
    // for the select/unselect all option.
    const toggleFilters = useCallback((filterToToggle, allFiltersAction = undefined) => {
        if (filterToToggle === undefined) {
            setActiveFilters(allFiltersAction ? filterNames : []);
            return
        }

        setActiveFilters(prevActive => {
            const a = new Set(prevActive);

            if (a.has(filterToToggle)) a.delete(filterToToggle);
            else a.add(filterToToggle);

            return Array.from(a);
        })
    }, [filterNames, setActiveFilters]);

    // This variable holds the time when the user last pressed the refresh button.
    const [ lastUpdateTime, setLastUpdateTime ] = useState(timeStr);

    // This function refreshes the orders tree by invoking a request to the API. 
    function refreshTree() {
        trackPromise(
            fetchFunc(LINKS.XML_GET_VISITS, {
                method: 'GET'
            })
            .then(({ status, visit_data }) => {
                setFetchStatus(status);
                if (status === 'success' || status === 'no-visits') {
                    setData(visit_data);
                }
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
            })
        , 'treeRefresh');
        setLastUpdateTime(timeStr);
    }

    return (
        <ModalProvider>
            <ToastProvider>
                <div className="d-flex justify-content-end align-items-center">
                    <p className="me-auto m-0">Σύνολο επισκέψεων: {data.length > 0 ? data.length : ""}</p>
                    <LoadingIndicatorSm area='treeRefresh' />
                    <OrdersTreeRefresher refreshTree={refreshTree} lastUpdateTime={lastUpdateTime} />
                    { (!isMobile) && <OrdersFilter filterNames={filterNames} activeFilters={activeFilters} toggleFilters={toggleFilters} />}
                </div>
                <LoadingIndicator area='tree' />
                <OrdersDateTree dates={tree} />
                <OrdersAlert status={fetchStatus} />
                <ResultsModal />
                <HistoryModal />
                <InfoModal refreshTree={refreshTree} />
                <UnsettledModal />
                <PdfToast />
            </ToastProvider>
        </ModalProvider>
    )
}

// /**
//  * @typedef {{ [year: string]: { [month: string]: Array<{ date: string, visit: number, tags: Array<string> }> } }} TreeStructure
//  */
