import { OrdersDatesTable } from "./OrdersDatesTable";
import { OrdersList, OrdersListItem } from "./OrdersListComponents";

export default function OrdersDateTree({ dates }) {

    return (
        <OrdersList show={true}>
            {
                // Sort data per year descending
                Object.entries(dates).sort((a, b) => b[0] - a[0]).map(([year, months]) => (
                    <OrdersListItem key={year} text={year}>
                        <OrdersList>
                            {
                                // Sort data per month descending
                                Object.entries(months).sort((a, b) => b[0] - a[0]).map(([month, data]) => (
                                    <OrdersListItem key={month} text={month}>
                                        <OrdersDatesTable rows={data} />
                                    </OrdersListItem>
                                ))
                            }
                        </OrdersList>
                    </OrdersListItem>
                ))
            }
        </OrdersList>
    )
}