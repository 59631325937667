import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { useTitle } from 'hooks/useTitle';
import { useJsonFetch } from 'hooks/useFetch';
import { getJWTinfo, validJWT } from 'utils/jwt';

import NewPwdForm from 'components/NewPwdForm';
import TokenAlert from 'pages/activate/TokenAlert';
import ProgressBar from 'pages/activate/ProgressBar';
import ActivateAlert from 'pages/activate/ActivateAlert';
import LoadingIndicator from 'components/LoadingIndicator';

// This component works in three steps:
// First, it verifies the activation token given as a URL parameter.
// Second, it displays a form to the user and activates the account when the user submits the form.
// Third, it receives the activation response status and if it is successful, it also logs the user in.
export default function ActivateManager() {

    useTitle("Ενεργοποίηση λογαριασμού");
    
    const auth = useAuth();
    const { jwt } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    // Token validation and activation request data
    const [ loginTokens, setLoginTokens ] = useState({});
    const [ prepareStatus, setPrepareStatus ] = useState('no-fetch'); 
    const [ activationStatus, setActivationStatus ] = useState('no-fetch');

    // Timer data
    let timer;
    const [ count, setCount ] = useState(0);

    // This function is invoked when the user submits the form and 
    // generates a request to the activation endpoint.
    function activationHandler(pwd, phone) {
        trackPromise(
            fetchFunc(LINKS.AUTH_ACTIVATE,  {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify({ password: pwd, phone: phone })
            })
            .then(({ msg, status, access_token, refresh_token }) => {
                setActivationStatus(status);
                setLoginTokens({ accessToken: access_token, refreshToken: refresh_token });
            })
            .catch(() => {
                setActivationStatus('error');
            })
        , 'activate');
    }

    // This function sets activationStatus to 'no-fetch' to clear any messages.
    function statusReseter() {
        setActivationStatus('no-fetch');
    }
    
    // This hook runs when the page loads and verifies the activation token.
    // It locally checks if the token has expired. If not, it creates a request to backend API.
    // The API response if the token is valid or if it has been already used.
    useEffect(() => {
        if (!validJWT(jwt, "activate")) { 
            setPrepareStatus('token-expired');
            return;
        }
        trackPromise(
            fetchFunc(LINKS.VERIFY_ACTIVATION_TOKEN, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })
            .then(({ msg, status }) => setPrepareStatus(status))
            .catch(() => setPrepareStatus('error'))
        , "validateToken");
    }, [jwt])

    // This hook runs after the change password request returns a success status.
    // It uses setInterval to create a timer and updates the count every 100 ms.
    // The hooks runs every time the count changes, but only one time is created thanks to !timer.
    // After 40 runs, the timer is cleared and login occurs.
    useEffect(() => {
        if (activationStatus !== 'success') return;
        timer = !timer && setInterval(() => {
            setCount(prevCount => prevCount + 1)
        }, 100)
        if (count === 40) {
            clearInterval(timer);
            auth.signin(loginTokens.accessToken, loginTokens.refreshToken);
        }
        return () => clearInterval(timer);
    }, [activationStatus, count]);


    if (auth.user) return <Navigate to={ROUTES.DEFAULT} replace={true} />
    else return (
        <div className="row justify-content-center">
            <div className="col-md-9">
                <h4 className='mb-4'>Ενεργοποίηση λογαριασμού</h4>
                <TokenAlert status={prepareStatus} />
                <LoadingIndicator area="validateToken" />
                {(prepareStatus === 'token-valid') && <NewPwdForm email={getJWTinfo(jwt).email} submitCallback={activationHandler} clearCallback={statusReseter} />}
                <LoadingIndicator area="activate" />
                <ActivateAlert status={activationStatus} />
                <ProgressBar width={Math.ceil(count / 40 * 100)} />
            </div>
        </div>
    )
}
