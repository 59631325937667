import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import { trackPromise } from 'react-promise-tracker';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { useJsonFetch } from 'hooks/useFetch';
import LoadingIndicator from 'components/LoadingIndicator';
import DelegationAlert from 'pages/delegate/DelegationAlert';

export default function DelegationForm() {

    const [ delegateDescr, setDelegateDescr ] = useState('')
    const [ delegateEmail, setDelegateEmail ] = useState('')
    const [ delegateDuration, setDelegateDuration ] = useState(2);

    const auth = useAuth();
    const fetchFunc = useJsonFetch();
    const [ tmpPwd, setTmpPwd ] = useState(undefined);
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');
    const [ submitDisabled, setSubmitDisabled ] = useState(false);

    // This function adds a new delegate with a request to the API.
    function addNewDelegate(e) {
        e.preventDefault();
        setFetchStatus('no-fetch'); // Clear previous fetch messages.
        setSubmitDisabled(true);    // Disable submit button to prevent multiple additions.
        trackPromise(
            fetchFunc(LINKS.DELEGATE_ADD, {
                method: 'POST',
                body: JSON.stringify({
                    userDescr: auth.info?.name ?? "",
                    delegateEmail: delegateEmail,
                    delegateDescr: delegateDescr,
                    duration: delegateDuration
                })
            })
            .then(({ status, pwd }) => {
                setFetchStatus(status);
                setTmpPwd(pwd);
                if (status !== 'success') {
                    setSubmitDisabled(false); // On error, enable submission again.
                }
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
                setSubmitDisabled(false); // On error, enable submission again.
            })
        , 'delegate')
    }

    if (isMobile) return (
        <div className="row justify-content-center mx-0">
            <div>
                <div className="row">
                    <h4 className='mb-3'>Παραχώρηση πρόσβασης</h4>
                    <p className='fs-7'>Για να επιτρέψετε σε κάποιο άλλο άτομο να προβάλει τα αποτελέσματα και το ιστορικό των εξετάσεων σας, συμπληρώστε την διεύθυνση e-mail στο παρακάτω πεδίο και πατήστε το κουμπί "Yποβολή".</p>
                </div>
                <div className="row">
                    <div className="col-12 border border-2 rounded-2 px-3 py-2">
                        <form className="row g-2 fs-7" onSubmit={addNewDelegate}>
                            <div className="col-12">
                                <label htmlFor="doctorDescr" className="form-label mb-0">Ονοματεπώνυμο</label>
                                <input type="text" className="form-control" id="doctorDescr" value={delegateDescr} onChange={e => setDelegateDescr(e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="doctorEmail" className="form-label mb-0">Ε-mail</label>
                                <input type="email" className="form-control" id="doctorEmail" value={delegateEmail} onChange={e => setDelegateEmail(e.target.value)} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="duration" className="form-label mb-0">Διάρκεια (ημέρες)</label>
                                <input type="number" className="form-control" min={1} max={10} value={delegateDuration} onChange={e => setDelegateDuration(e.target.value)} required />
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <Link className='btn btn-sm btn-secondary' to={ROUTES.DELEGATE_LIST}>Επιστροφή</Link>
                                <button type="submit" className="btn btn-sm btn-primary" disabled={submitDisabled}>Υποβολή</button>
                            </div>
                        </form>
                    </div>
                </div>
                <LoadingIndicator area="delegate" />
                <div className="row mt-4">
                    <DelegationAlert status={fetchStatus} pwd={tmpPwd} />
                </div>
            </div>
        </div>
    )
    else return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <div className="row">
                    <h4>Παραχώρηση πρόσβασης</h4>
                    <p>Για να επιτρέψετε σε κάποιο άλλο άτομο να προβάλει τα αποτελέσματα και το ιστορικό των εξετάσεων σας, συμπληρώστε την διεύθυνση e-mail στο παρακάτω πεδίο και πατήστε το κουμπί "Yποβολή".</p>
                </div>
                <div className="row">
                    <div className="col-12 border border-2 rounded-2 p-4">
                        <form className="row g-3" onSubmit={addNewDelegate}>
                            <div className="col-12">
                                <label htmlFor="doctorDescr" className="form-label">Ονοματεπώνυμο</label>
                                <input type="text" className="form-control" id="doctorDescr" value={delegateDescr} onChange={e => setDelegateDescr(e.target.value)} required />
                            </div>
                            <div className="col-6">
                                <label htmlFor="doctorEmail" className="form-label">Ε-mail</label>
                                <input type="email" className="form-control" id="doctorEmail" value={delegateEmail} onChange={e => setDelegateEmail(e.target.value)} required />
                            </div>
                            <div className="col-6">
                                <label htmlFor="duration" className="form-label">Διάρκεια (ημέρες)</label>
                                <input type="number" className="form-control" min={1} max={10} value={delegateDuration} onChange={e => setDelegateDuration(e.target.value)} required />
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <Link className='btn btn-secondary' to={ROUTES.DELEGATE_LIST}>Επιστροφή</Link>
                                <button type="submit" className="btn btn-primary" disabled={submitDisabled}>Υποβολή</button>
                            </div>
                        </form>
                    </div>
                </div>
                <LoadingIndicator area="delegate" />
                <div className="row mt-4">
                    <DelegationAlert status={fetchStatus} pwd={tmpPwd} />
                </div>
            </div>
        </div>
    );
}
