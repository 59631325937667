import React from 'react';

export default function OrdersAlert({ status }) {
    switch (status) {
        case 'success':
            return <></>;
        case 'not-exists':
            return (
                <div className="alert alert-danger">Δεν βρέθηκαν δεδομένα. Παρακαλώ προσπαθήστε αργότερα.</div>
            );
        case 'no-visits':
            return (
                <div className="alert alert-warning">Δεν βρέθηκαν επισκέψεις.</div>
            );
        case 'error':
            return (
                <div className="alert alert-danger">Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.</div>
            )
        default:
            return <></>;
    }
}
