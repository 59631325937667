import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { LINKS } from 'data/links';
import { useAuth } from 'hooks/useAuth';
import { useJsonFetch } from 'hooks/useFetch';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from 'components/LoadingIndicator';
import DelegateLoginAlert from 'pages/delegateLogin/DelegateLoginAlert';

export default function DelegateLoginForm({ delegateEmail }) {

    const auth = useAuth();
    const { jwt } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    const [ pwd, setPwd ] = useState("");
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');

    function loginDelegate(e) {
        e.preventDefault();
        setFetchStatus('no-fetch');
        trackPromise(
            fetchFunc(LINKS.DELEGATE_LOGIN, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    password: pwd
                })
            })
            .then(({ status, tokens }) => {
                setFetchStatus(status);
                if (status === 'success') auth.signin(tokens.access, tokens.refresh);
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error');
            })
        , 'login');
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 border border-2 rounded-2 p-4">
                    <form className="row g-3" onSubmit={loginDelegate}>
                        <div className="col-12">
                            <label htmlFor="email" className="form-label">E-mail</label>
                            <input type="text" className="form-control" id="email" value={delegateEmail} disabled/>
                        </div>
                        <div className="col-12">
                            <label htmlFor="pwd" className="form-label">Κωδικός πρόσβασης</label>
                            <input type="password" className="form-control" id="pwd" value={pwd} onChange={e => setPwd(e.target.value)} />
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">Υποβολή</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row mt-4">
                <LoadingIndicator area='login' />
                <DelegateLoginAlert status={fetchStatus} />
            </div>
        </React.Fragment>
    );
}
