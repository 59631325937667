import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Navigate, useParams } from 'react-router-dom';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';
import { useTitle } from 'hooks/useTitle';
import { useJsonFetch } from 'hooks/useFetch';

import HashAlert from 'pages/activateHash/HashAlert';
import LoadingIndicator from 'components/LoadingIndicator';
import ActivationForm from 'pages/activateHash/ActivationForm';

// This component works in three steps:
// First, it verifies the hash user id (pa_code) given as a URL parameter.
// Second, it displays a form to the user and activates the account when the user submits the form.
// Third, it receives the activation response status and if it is successful, it also logs the user in.
export default function ActivateHashManager() {

    useTitle("Ενεργοποίηση λογαριασμού");
    
    const auth = useAuth();
    const { hashId } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    // State variables for login tokens and fetch statuses
    const [ loginTokens, setLoginTokens ] = useState({});
    const [ prepareStatus, setPrepareStatus ] = useState('no-fetch'); 
    const [ activationStatus, setActivationStatus ] = useState('no-fetch');

    // This function is invoked when the user submits the form and 
    // updates the relevant fetch status and login tokens
    function activationHandler(status, tokens) {
        setActivationStatus(status);
        setLoginTokens(tokens);
    }
    
    // This hook runs when the page loads and verifies the hash ID in the URL.
    // On success, the endpoint returns an activation token which is stored in session storage.
    useEffect(() => {
        trackPromise(
            fetchFunc(LINKS.ACTIVATE_HASH_PREPARE, {
                method: "POST",
                body: JSON.stringify({
                    'hashId': hashId
                })
            })
            .then(({ status, token }) => {
                setPrepareStatus(status);
                if (status === 'valid') sessionStorage.setItem('token', token);
            })
            .catch(() => setPrepareStatus('error'))
        , "validateUrl");
    }, [hashId])

    // This hook runs after the account has been successfully activated.
    // It uses setTimeout to delay login for 5 seconds (or 5000 ms).
    useEffect(() => {
        if (activationStatus !== 'success') return;
        setTimeout(() => {
            auth.signin(loginTokens.access, loginTokens.refresh);
        }, 5000)
    }, [activationStatus]);


    if (auth.user) return <Navigate to={ROUTES.DEFAULT} replace={true} />
    else if (prepareStatus !== 'valid') return (
        <div className="row justify-content-center">
            <div className="col-md-9">
                <h4 className='mb-3'>Ενεργοποίηση λογαριασμού</h4>
                <LoadingIndicator area="validateUrl" />
                <HashAlert status={prepareStatus} />
            </div>
        </div>
    )
    else return (
        <div className="row justify-content-center">
            <div className="col-md-9">
                <h4 className='mb-3'>Ενεργοποίηση λογαριασμού</h4>
                <p className={isMobile && 'fs-7'}>Για να ενεργοποιήσετε τον λογαριασμό σας, συμπληρώστε την φόρμα και πατήστε "Υποβολή".</p>
                <ActivationForm activationCallback={activationHandler} />
            </div>
        </div>
    )
}
