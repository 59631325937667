import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthProvider, withSecurity } from 'hooks/useAuth';

import Home from 'components/Home';
import Navbar from 'components/Navbar';
import Logout from 'pages/login/Logout';
import ForgotForm from 'pages/forgot/ForgotForm';
import LoginMechanism from 'pages/login/LoginMechanism';
import RecoverManager from 'pages/recover/RecoverManager';
import ActivateManager from 'pages/activate/ActivateManager';
import RegistrationManager from 'pages/register/RegistrationManager';
import DelegateLoginMechanism from 'pages/delegateLogin/DelegateLoginMechanism';
import MaintainanceNotification from 'components/MaintainanceNotification';

import { default as _Orders } from 'pages/ordersTree/Orders';
import { default as _DelegationForm } from 'pages/delegate/DelegationForm';
import { default as _DelegationTable } from 'pages/delegate/DelegationTable';
import ActivateHashManager from 'pages/activateHash/ActivateHashManager';

const Orders = withSecurity(_Orders, 'delegate');
const DelegationForm = withSecurity(_DelegationForm, 'user')
const DelegationTable = withSecurity(_DelegationTable, 'user')

export default function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Navbar />
                <MaintainanceNotification />
                <Routes>
                    <Route path='/'>
                        <Route index element={<Home />} />
                        <Route path='auth'>
                            <Route path='login' element={<LoginMechanism />} />
                            <Route path='logout' element={<Logout />} />
                            <Route path='forgot' element={<ForgotForm />} />
                            <Route path='activate/:jwt' element={<ActivateManager />} />
                            <Route path='recover/:jwt' element={<RecoverManager />} />
                        </Route>
                        <Route path='register/:hashedId' element={< RegistrationManager />} />
                        <Route path='orders' element={< Orders />} />
                        <Route path='delegate'>
                            <Route path='add' element={< DelegationForm />} />
                            <Route path='list' element={< DelegationTable />} />
                            <Route path='login/:jwt' element={<DelegateLoginMechanism />} />
                        </Route>
                        <Route path='activate/:hashId' element={<ActivateHashManager />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}
