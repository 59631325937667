import React, { useEffect, useState, useMemo } from 'react';

export default function SubexamSelect({ subexamData, setGlobalExam }) {

    // The local selectedSubexam variable helps avoid updating modal component while this is rendering.
    const [ selectedSubexam, setSelectedSubexam ] = useState(undefined);

    // This hook memorizes the dropdown list for subexams.
    // If there is only one subexam available, the dropdown is disabled.
    // Else there is a dropdown with all available subexams.
    const dropdown = useMemo(() => {
        if (!subexamData) return <></>;
        if (subexamData?.length < 1) return (
            <div className="alert alert-warning">Δεν βρέθηκαν υποεξετάσεις.</div>
        )
        else if (subexamData?.length === 1) {
            return (
                <select className="form-select" disabled>
                    <option value={subexamData[0].ExamCode}>{subexamData[0].ExamName}</option>
                </select>
            )
        }
        else return (
            <select className="form-select" value={selectedSubexam} onChange={e => setSelectedSubexam(e.target.value)}>
                <option value=''>Επιλέξτε υποεξέταση</option>
                {subexamData.map(subexamObj => (
                    <option key={subexamObj.ExamCode} value={subexamObj.ExamCode}>{subexamObj.ExamName}</option>
                ))}
            </select>
        )
    }, [subexamData, selectedSubexam]);

    // This hook sets the global selected exam variable.
    // If there is only one subexam available, the user does not need to choose;
    // the state is updated automatically.
    useEffect(() => {
        if (subexamData?.length === 1) {
            setGlobalExam(subexamData[0].ExamCode);
            return;
        }
        setGlobalExam(selectedSubexam);
    }, [selectedSubexam, subexamData?.length]);

    return (
        <React.Fragment>
            {dropdown}
        </React.Fragment>
    )
}
