import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Navigate, useParams } from 'react-router-dom';

import { LINKS } from 'data/links';
import { ROUTES } from 'data/routes';
import { getJWTinfo } from 'utils/jwt';
import { useAuth } from 'hooks/useAuth';
import { useFetch } from 'hooks/useFetch';

import LoadingIndicator from 'components/LoadingIndicator';
import DelegateLoginForm from 'pages/delegateLogin/DelegateLoginForm';

export default function DelegateLoginMechanism() {

    const auth = useAuth();
    const { jwt } = useParams();
    const fetchFunc = useFetch({ secure: false });
    const [ verifyStatus, setVerifyStatus ] = useState(undefined);
    
    useEffect(() => {
        trackPromise(
            fetchFunc(LINKS.DELEGATE_VERIFY, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
            .then(resp => setVerifyStatus(resp.status))
            .catch(e => {
                console.error(e);
                setVerifyStatus(500);
            })
        , 'verify')
    }, []);

    if (auth.user) {
        return <Navigate to={ROUTES.DEFAULT} />
    }

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <h4 className='mb-4'>Σύνδεση εξουσιοδοτημένου χρήστη</h4>
                <LoadingIndicator area='verify' />
                { verifyStatus === 200 && <DelegateLoginForm delegateEmail={getJWTinfo(jwt)['email']} /> }
                { [401, 422].includes(verifyStatus) && 
                    <div className="alert alert-danger">
                        Ο σύνδεσμος έχει λήξει. Αν θέλετε να ανανεώσετε το δικαίωμα πρόσβασης στον ιατρικό φάκελο του συγκεκριμένου ασθενούς, θα πρέπει να επικοινωνήσετε μαζί του.
                    </div>
                }
                { verifyStatus === 500 &&
                    <div className="alert alert-danger">
                        Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                    </div>
                }
            </div>
        </div>
    )
}
