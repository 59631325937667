import React from 'react';
import { ROUTES } from 'data/routes';
import { Link } from 'react-router-dom';

export default function ActivateAlert({ status, width }) {
    switch(status) {
        case 'no-fetch':
            return <></>;
        case 'error':
            return (
                <div className="alert alert-danger">
                    Η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'not-exists':
            return (
                <div className="alert alert-danger">
                    Συνέβη κάποιο σφάλμα κατά την ενεργοποίηση του λογαριασμού σας. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            );
        case 'already-activated':
            return (
                <div className="alert alert-danger">
                    Ο λογαρισμός έχει ήδη ενεργοποιηθεί. Μπορείτε να συνδεθείτε από την <Link to={ROUTES.AUTH_LOGIN}>σελίδα σύνδεσης</Link>.
                </div>
            );
        case 'token-error':
            return (
                <div className="alert alert-danger">
                    Συνέβη κάποιο σφάλμα κατά την ενεργοποίηση του λογαριασμού σας. Παρακαλώ προσπαθήστε αργότερα.
                </div>
            )
        case 'phone-error':
            return (
                <div className="alert alert-danger">
                    Το κινητό τηλέφωνο δεν ταιριάζει με το δηλωθέν κινητό. Παρακαλώ συμπληρώστε το σωστό νούμερο.
                </div>
            )
        case 'success':
            return (
                <div className="alert alert-success">
                    Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς. Θα μεταφερθείτε αυτόματα στην αρχική σελίδα.
                </div>
            );
        default:
            return <></>;
    }
}
