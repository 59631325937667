import { useEffect } from "react";

export function useTitle(/** @type {string} */title) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = `${title} | ${prevTitle}`;
        return () => {
            document.title = prevTitle;
        }
    }, [title]);
}