import { Children, cloneElement } from "react";

/**
 * @param {*} children Component children (props.children)
 * @param {{ [propKey: string]: unknown }} propsToAdd Object containing the props to be added or replaced
 * @returns Renderable children
 */
function addPropsToChildren(children, propsToAdd) {
    return Children.map(children, (child, index) => cloneElement(child, { __addPropsIndex: index, ...propsToAdd }));
}

export { addPropsToChildren }