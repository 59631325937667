import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { LINKS } from 'data/links';
import { useJsonFetch } from 'hooks/useFetch';
import TokenAlert from 'pages/register/TokenAlert';
import LoadingIndicator from 'components/LoadingIndicator';
import RegistrationForm from 'pages/register/RegistrationForm';

export default function RegistrationManager() {

    const { hashedId } = useParams();
    const fetchFunc = useJsonFetch({ secure: false });

    const [ candidateData, setCandidateData ] = useState(undefined);
    const [ prepareStatus, setPrepareStatus ] = useState('no-fetch');

    useEffect(() => {
        trackPromise(
            fetchFunc(LINKS.REGISTER_PREPARE, {
                method: 'POST',
                body: JSON.stringify({ hashedId })
            })
            .then(({ status, data }) => {
                setPrepareStatus(status);
                setCandidateData(data);
            })
            .catch(e => {
                console.error(e);
                setPrepareStatus('error');
                setCandidateData(undefined);
            })
        , 'register');
    }, [])

    return (
        <div className="row justify-content-center">
            <div className="col-md-9">
                <h4 className="mb-3">Εγγραφή νέου χρήστη</h4>
                <TokenAlert status={prepareStatus} />
                <RegistrationForm data={candidateData} hashedId={hashedId} />
                <LoadingIndicator area="register" />
            </div>
        </div>
    );
}
