import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'data/routes';
import { useAuth } from 'hooks/useAuth';

export default function Logout() {

    const auth = useAuth();

    useEffect(() => {
        auth.signout();
    }, []);

    return (
        <div className="row justify-content-center mx-0">
            <div className="col-md-7">
                <div className="alert alert-secondary">
                    <h5 className='mb-4'>Σας ευχαριστούμε που χρησιμοποιήσατε την υπηρεσία Diagnosis Portal</h5>
                    <p className='mb-1'>
                        Έχετε αποσυνδεθεί επιτυχώς. Αν επιθυμείτε να συνδεθείτε εκ νέου, πατήστε <Link to={ROUTES.AUTH_LOGIN} replace={true}>εδώ</Link>.
                    </p>
                </div>
            </div>
        </div>
    )
}
