import { useEffect, useMemo, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { LINKS } from "data/links";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useJsonFetch } from "hooks/useFetch";

// This component returns a dropdown or button with links that open a DICOM viewer in a new tab, if 2 conditions are fulfilled:
// 1) The user is a delegate 
// 2) The exam group has an exam with the "IsRIS" property set to "true" 
export default function DicomDropdown({ groupTestObj }) {

    const auth = useAuth();
    const modal = useModal();
    const fetchFunc = useJsonFetch();
    const [ urlList, setUrlList ] = useState([]);

    // Find the RIS exam ID in the exam group
    const risExamId = useMemo(() => {
        const risTest = Object.values(groupTestObj).filter(val => val["IsRIS"]);
        if (risTest.length > 0) return risTest[0]["ExamId"]
        else return null;
    }, [groupTestObj])

    // Fetch the DICOM URLs for the specific RIS exam
    useEffect(() => {
        fetchFunc(LINKS.GET_DICOM_URL, {
            method: 'POST',
            body: JSON.stringify({
                order_id: modal.orderId,
                exam_id: risExamId
            })
        })
        .then(({ status, url}) => {
            if (status === 'success') {
                setUrlList(url);
            }
            else if (status === 'error') {
                setUrlList([]);
            }
            else {
                setUrlList([]);
            }
        })
        .catch(e => {
            console.error(e);
            setUrlList([]);
        })
    }, [risExamId])

    if (auth.user.accessLevel !== 'delegate') return <></>;

    if (risExamId) return (
        <OverlayTrigger
            key='ris-overlay'
            placement='left'
            overlay={
                <Tooltip id='ris-tooltip'>
                    Η υπηρεσία αυτή διατίθεται αποκλειστικά σε ιατρούς και απαιτεί ειδικό όνομα χρήστη και συνθηματικό. Επικοινωνήστε με το διαγνωστικό κέντρο.
                </Tooltip>
            }
        >
        {(urlList.length > 1) ?
            <Dropdown className="ms-2">
                <Dropdown.Toggle variant="primary" size="sm">DICOM studies</Dropdown.Toggle>
                <Dropdown.Menu>
                    { urlList.map((elem, index) => {
                        if (elem.is_master) return (
                            <Dropdown.Item href={elem?.url} target="_blank" rel="noopener noreferrer" className="text-primary"><strong>Master study</strong></Dropdown.Item>
                        )
                        else return (
                            <Dropdown.Item href={elem?.url} target="_blank" rel="noopener noreferrer" className="text-primary">DICOM study {index + 1}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        : 
            <a className="btn btn-primary btn-sm ms-2" href={urlList[0]?.url} target="_blank" rel="noopener noreferrer">
                DICOM study
            </a>
        }
        </OverlayTrigger>
    )
    else return <></>;
}
