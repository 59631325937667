import ReactModal from 'react-modal';
import { useModal } from 'hooks/useModal';
import React, { useEffect, useState } from 'react';
import { LINKS } from 'data/links';
import { useJsonFetch } from 'hooks/useFetch';
import { trackPromise } from 'react-promise-tracker';
import ExamGroupAccordion from './ExamGroupAccordion';
import LoadingIndicator from 'components/LoadingIndicator';
import ResultsAlert from './ResultsAlert';

export default function ResultsModal() {

    ReactModal.setAppElement(document.getElementById('root'));

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(180, 180, 180, 0.75)'
        },
        content: {
            position: 'absolute',
            top: '100px',
            left: '200px',
            right: '200px',
            bottom: '100px',
            padding: '30px'
        },
      };
    
    const modal = useModal();
    const fetchFunc = useJsonFetch();
    const [ data, setData ] = useState(undefined);
    const [ layout, setLayout ] = useState(undefined);
    const [ fetchStatus, setFetchStatus ] = useState('no-fetch');

    useEffect(() => {
        if (!modal.orderId) return;
        trackPromise(
            Promise.all([
                fetchFunc(`${LINKS.XML_GET_ORDER_RESULTS}/${modal.orderId}`, { method: 'GET' }),
                fetchFunc(`${LINKS.XML_GET_ORDER_LAYOUT}/${modal.orderId}`, { method: 'GET' })
            ])
            .then(([ resultObj, layoutObj ]) => {
                setData(resultObj.data);
                setLayout(layoutObj.data);
                if (resultObj.status === 'success' && layoutObj.status === 'success') setFetchStatus('success');
                else if (resultObj.status !== 'success') setFetchStatus('result-error');
                else setFetchStatus('layout-error');
            })
            .catch(e => {
                console.error(e);
                setFetchStatus('error')
            })
        , 'results')
    }, [modal.orderId]);

    function clearAndClose() {
        modal.closeResultsModal();
        setData(null);
        setLayout(null);
        setFetchStatus('no-fetch');
    }

    return (
        <ReactModal isOpen={modal.resultsModalOpen} onRequestClose={clearAndClose} style={customStyles} ariaHideApp={false} >
            <button type="button" className="btn-close mt-1 ms-1" title="Κλείσιμο" style={{ position: 'fixed', top: '100px', left: '200px' }} onClick={clearAndClose}></button>
            <h5>Αποτελέσματα επίσκεψης {modal.orderDate}</h5>
            <LoadingIndicator area='results' />
            <ResultsAlert status={fetchStatus} />
            <div className='accordion' id='orderresultsAccordion'>
                {   (data && layout) &&
                    Object.keys(data['Groups']).map(
                        (key, i) => <ExamGroupAccordion 
                                    id={i}
                                    key={`group-printout-${i}`}
                                    groupName={key}
                                    groupData={data['Groups'][key]}
                                    groupLayout={layout['Groups'][key]}
                                />
                    )
                }
            </div>
        </ReactModal>
    );
}
