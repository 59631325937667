import { useId } from "react";
import { useToggle } from "hooks/useToggle";
import { useEventListener } from "hooks/useEventListener";

export default function OrdersFilter({ filterNames, activeFilters, toggleFilters }) {
    const dropdownId = useId();
    const [ dropdownRef, _, dropdownToggle ] = useToggle();

    useEventListener("click", e => {
        if (e.target?.closest("#filtersDropdown") != null) return;
        dropdownToggle(false);
    });

    return (
        <div className="dropdown" id="filtersDropdown" style={{ width: "fit-content" }}>
            <button className="btn btn-light dropdown-toggle" type="button" id={dropdownId} onClick={() => dropdownToggle()}>
                <i className="bi bi-funnel"></i>
                <span className="mx-1">Φίλτρα</span>
            </button>
            <ul className="dropdown-menu" aria-labelledby={dropdownId} ref={dropdownRef} style={{ position: "absolute", right: 0, userSelect: "none" }}>
                <li>
                    <div className="dropdown-item unhover d-flex justify-space-between gap-2">
                        <button type="button" className="btn btn-secondary" onClick={() => toggleFilters(undefined, false)}>Αποεπιλογή όλων</button>
                        <button type="button" className="btn btn-primary" onClick={() => toggleFilters(undefined, true)}>Επιλογή όλων</button>
                    </div>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <ul className="list-unstyled" style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                        {
                            filterNames.sort().map(
                                filter => 
                                    <li key={filter} onClick={() => toggleFilters(filter)}>
                                        <Filter checked={activeFilters.includes(filter)}>
                                            {filter}
                                        </Filter>
                                    </li>
                            )
                        }
                    </ul>
                </li>
                {/* <li><hr className="dropdown-divider" /></li>
                <li>
                    <div className="dropdown-item unhover d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" disabled={true}>Εφαρμογή φίλτρου</button>
                    </div>
                </li> */}
            </ul>
        </div>
    );
}

function Filter({ checked, children }) {
    const filterId = useId();
    
    return (
        <div className="dropdown-item" style={{ cursor: "pointer" }}>
            <input type="checkbox" className="form-check-input ms-0 me-2" id={filterId} checked={checked} onChange={e => {}} />
            <label htmlFor={filterId} className="form-check-label">{children}</label>
        </div>
    )
}