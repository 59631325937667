import { useCallback, useEffect, useRef, useState, MutableRefObject } from "react";

/**
 * @param {boolean} toggleState Wheather to force add or remove the class
 * @param {string} [className] The name of the class to toggle. Defaults to Bootstrap's `show` 
 * @returns The ref to attach to the togglable element
 */
function useToggleRef(toggleState, className = "show") {
    const ref = useRef();

    useEffect(() => {
        ref.current?.classList.toggle(className, toggleState);
    }, [toggleState]);

    return ref;
}

/**
 * @param {boolean} [initialState] The toggle state to start with. Defaults to false.
 * @param {string} [className] The name of the class to toggle. Defaults to Bootstrap's `show` 
 * @returns {[ MutableRefObject, boolean, (value: boolean | undefined) => void ]} The ref to attach to the togglable element
 */
function useToggle(initialState = false, className = "show") {
    const [ active, setActive ] = useState(initialState);
    const ref = useToggleRef(active, className);

    const toggle = useCallback(value => setActive(prevActive => (value == null) ? !prevActive : Boolean(value)), [setActive]);

    return [ ref, active, toggle ];
}

export { useToggleRef, useToggle }