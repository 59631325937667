import React, { useMemo } from 'react';

function AbnormalIcon({ isAbnormal }) {
    if (isAbnormal) return (
        <div title="Εκτός φυσιολογικών τιμών">
            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" fill="red" className="bi bi-circle-fill" viewBox="0 0 16 16" title="Εκτός φυσιολογικών τιμών">
                <circle cx="8" cy="8" r="8"/>
            </svg>
        </div>
    );
    else return <></>;
}

export default function HistoryTable({ historyData, fieldCount }) {

    // This hook runs when history data is loaded and transforms it into a table.
    // The data is an array of objects: [{ Date, ResultValue, IsAbnormal, Units, NormalValues: { UpperLimit, LowerLimit, Text }, Precision }, ...]
    // A row is generated for each date and result value, but the normal values and units are not repeated if they remain the same.
    const tableBody = useMemo(() => {

        if (!historyData) return undefined;

        let tbodyRows = [];         // Stores the table rows.
        let unitsCount = [];        // Stores how many times each units value appears.
        let normalValuesCount = []; // Stores how many times each normal values string appears.
        let currUnits, currNormalValues, tmpUnitsCount, tmpNormalValuesCount;   // Temporal variables used in the loop.

        // This loop detects when the units or normal values string change.
        // The number of consequtive times a string appears is pushed to the equivalent array. 
        historyData.forEach((resObj, i) => {

            // Initialize temporal loop variables.
            if (i === 0) {
                currNormalValues = resObj.NormalValues.Text ?? "";
                tmpNormalValuesCount = 0;
                currUnits = resObj.Units ?? ""
                tmpUnitsCount = 0;
            }

            // Increase count if normal value does not change or push the count to the array.
            if (currNormalValues === (resObj.NormalValues.Text ?? "")) tmpNormalValuesCount++;
            else {
                normalValuesCount.push(tmpNormalValuesCount);
                currNormalValues = resObj.NormalValues.Text ?? ""
                tmpNormalValuesCount = 1;
            }

            // Increase count if units do not change or push the count to the array.
            if (currUnits === (resObj.Units ?? "")) tmpUnitsCount++;
            else {
                unitsCount.push(tmpUnitsCount);
                currUnits = resObj.Units ?? ""
                tmpUnitsCount = 1;
            }

        });
        // Push the last counts to the arrays.
        unitsCount.push(tmpUnitsCount);
        normalValuesCount.push(tmpNormalValuesCount);

        // Push one table row for each entry. The row contains the date, result value (bold if abnormal), an abnormal icon, units and normal values.
        // The units and normal values span vertically while they remain unchanged to avoid unnecessary information repetition.
        historyData.forEach((resObj, i) => {

            if (i === 0) {
                currNormalValues = resObj.NormalValues.Text ?? "";
                currUnits = resObj.Units ?? "";
                tbodyRows.push(
                    <tr key={`entry-${i}`}>
                        <td className='py-0 pe-2'>{resObj.Date}</td>
                        <td className={`py-0 text-center ${resObj.IsAbnormal && 'fw-bold'}`}>{resObj.ResultValue}</td>
                        <td className='py-0 pe-3'>{<AbnormalIcon isAbnormal={resObj.IsAbnormal} />}</td>
                        <td className='py-0 pe-2 text-center' rowSpan={unitsCount.shift()}>{resObj.Units}</td>
                        {(fieldCount < 3) && <td className='py-0 pe-2 text-center' rowSpan={normalValuesCount.shift()}>{resObj.NormalValues.Text}</td>}
                    </tr>
                );
            }
            else {
                if (currNormalValues === (resObj.NormalValues.Text ?? "") && currUnits === (resObj.Units ?? "")) {
                    tbodyRows.push(
                        <tr key={`entry-${i}`}>
                            <td className='py-0 pe-2'>{resObj.Date}</td>
                            <td className={`py-0 text-center ${resObj.IsAbnormal && 'fw-bold'}`}>{resObj.ResultValue}</td>
                            <td className='py-0 pe-3'>{<AbnormalIcon isAbnormal={resObj.IsAbnormal} />}</td>
                        </tr>
                    )
                }
                else if (currNormalValues !== (resObj.NormalValues.Text ?? "") && currUnits === (resObj.Units ?? "")) {
                    tbodyRows.push(
                        <tr key={`entry-${i}`} className='border-top'>
                            <td className='py-0 pe-2'>{resObj.Date}</td>
                            <td className={`py-0 text-center ${resObj.IsAbnormal && 'fw-bold'}`}>{resObj.ResultValue}</td>
                            <td className='py-0 pe-3'>{<AbnormalIcon isAbnormal={resObj.IsAbnormal} />}</td>
                            {(fieldCount < 3) && <td className='py-0 pe-2 text-center' rowSpan={normalValuesCount.shift()}>{resObj.NormalValues.Text}</td>}
                        </tr>
                    );
                    currNormalValues = resObj.NormalValues.Text ?? "";
                }
                else if (currNormalValues === (resObj.NormalValues.Text ?? "") && currUnits !== (resObj.Units ?? "")) {
                    tbodyRows.push(
                        <tr key={`entry-${i}`} className='border-top'>
                            <td className='py-0 pe-2'>{resObj.Date}</td>
                            <td className={`py-0 text-center ${resObj.IsAbnormal && 'fw-bold'}`}>{resObj.ResultValue}</td>
                            <td className='py-0 pe-3'>{<AbnormalIcon isAbnormal={resObj.IsAbnormal} />}</td>
                            <td className='py-0 pe-2 text-center' rowSpan={unitsCount.shift()}>{resObj.Units}</td>
                        </tr>
                    );
                    currUnits = resObj.Units ?? "";
                }
                else {
                    tbodyRows.push(
                        <tr key={`entry-${i}`} className='border-top'>
                            <td className='py-0 pe-2'>{resObj.Date}</td>
                            <td className={`py-0 text-center ${resObj.IsAbnormal && 'fw-bold'}`}>{resObj.ResultValue}</td>
                            <td className='py-0 pe-3'>{<AbnormalIcon isAbnormal={resObj.IsAbnormal} />}</td>
                            <td className='py-0 pe-2 text-center' rowSpan={unitsCount.shift()}>{resObj.Units}</td>
                            {(fieldCount < 3) && <td className='py-0 pe-2 text-center' rowSpan={normalValuesCount.shift()}>{resObj.NormalValues.Text}</td>}
                        </tr>
                    );
                    currNormalValues = resObj.NormalValues.Text ?? "";
                    currUnits = resObj.Units ?? "";
                }
            }
        });
        return tbodyRows;
    }, [historyData, fieldCount]);
    
    if (!historyData) return <></>;
    return (
        <table id='historyTable' className="table table-sm table-borderless align-middle border border-2 fs-7 w-auto" style={{ whiteSpace: 'pre' }}>
            <thead>
                <tr className='border-bottom'>
                    <th className='pe-2'>Ημερομηνία</th>
                    <th className='pe-2' colSpan={(fieldCount > 3) ? 1 : 2}>Τιμή</th>
                    <th></th>
                    {(fieldCount < 3) && <th className='pe-2'>Φυσιολογικές τιμές</th>}
                </tr>
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </table>
    );
}
