import React, { useState } from 'react';
import { useModal } from 'hooks/useModal';
import { trackPromise } from 'react-promise-tracker';

export default function OrdersTreeRefresher({ refreshTree, lastUpdateTime }) {

    // This variable holds the timestamp after which the next refresh is allowed. The user can refresh data once every minute.
    const [ nextRefreshTimestamp, setNextRefreshTimestamp ] = useState(() => {
        let now = new Date();
        let then = new Date(now.getTime() + (1 * 60 * 1000));
        return then;
    });

    const modal = useModal();

    // This function refreshes the orders tree by invoking a request to the API. 
    // The request is created only if 1 minute has elapsed from the last update.
    // Else it creates a dummy promise resolving after 1 second to show a loading spinner.
    function checkAndRefreshTree() {
        const currTimestamp = new Date();
        if (currTimestamp > nextRefreshTimestamp) {
            refreshTree();
            modal.setChangeDetected(false); // Clear modal state
            setNextRefreshTimestamp(new Date(currTimestamp.getTime() + (1 * 60 * 1000)));
        }
        else {
            trackPromise(
                new Promise(res => {
                    setTimeout(() => res("Meaningless promise with timeout"), 1000)
                })
            , 'treeRefresh')
        }
    }

    return (
        <div className='d-flex'>
            <p className='p-0 my-0 ms-3 align-self-end' style={{ fontSize: '0.7em' }}>Τελευταία ενημέρωση στις {lastUpdateTime}</p>
            <button className="btn btn-primary mx-3 px-2 py-1" onClick={checkAndRefreshTree}>
                <i className="bi bi-arrow-clockwise" title="Ανανέωση" />
            </button>
        </div>
    )
}
